import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useState } from "react";
import { colors } from "./../styles/variables";
import Login from "./login/Login.component";
import { LocalContext } from "../LocalProvider";
// import ResetPassword from "./ResetPassword.component";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
      },
    },
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      margin: 0,
      height: "100%",
      maxHeight: "100%",
    },
  },
  containerStyle: {
    padding: "1.75rem 2.375rem 1.375rem 2.25rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem",
    },
  },
  titleStyle: {
    fontSize: "1.875rem",
    color: "#535353",
  },
  contentTextStyle: {
    fontSize: "1rem",
    color: "#000000",
  },
  btnStyle: {
    margin: "0 auto",
  },
  buttonContainerStyle: {
    paddingTop: "3.5rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.5rem",
    },
  },
  navStyle: {
    color: `${colors.lightBlue}`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    zIndex: 1000,
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#lightgray",
  },
}));

function CheckEmail(props) {
  const classes = useStyles();
  const { translate } = useContext(LocalContext);
  const [open, setOpen] = useState(true);
  const [backToLogin, setBackToLogin] = useState(false);
  const onBackToLogin = () => {
    setBackToLogin(true);
    setOpen(false);
  };
  return (
    <div>
      {backToLogin ? (
        // props.pageData.redirect === "login" ? (
        //   <Login handleCloseSignIn={props.handleCloseSignIn} />
        // ) : (
        //   <ResetPassword handleCloseSignIn={props.handleCloseSignIn} />
        // )
        // on click backToLogin redirect to login page
        <Login handleCloseSignIn={props.handleCloseSignIn} />
      ) : (
        // <Login handleCloseSignIn={props.handleCloseSignIn} />
        <Dialog
          open={open}
          onClose={props.handleCloseSignIn}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.paper }}
          fullWidth={true}
        >
          <div className={classes.containerStyle}>
            <Avatar className={classes.closeButton}>
              <IconButton aria-label="Close" onClick={props.handleCloseSignIn}>
                <CloseIcon />
              </IconButton>
            </Avatar>
            <DialogTitle id="form-dialog-title">
              <strong className={classes.titleStyle}>
                {translate("forgot_success_email_title")}
              </strong>
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.contentTextStyle}>
                {/* Password reset link has been sent to your registered email
                address. */}
                {translate("forgot_success_email_subTitle")}
              </DialogContentText>
              <DialogContentText className={classes.contentTextStyle}>
                {/* Follow the instructions shared in email to reset your password. */}
                {translate("forgot_success_email_description")}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.buttonContainerStyle}>
              <Button className={classes.btnStyle}>
                <strong onClick={onBackToLogin} className={classes.navStyle}>
                  {/* Back to Sign In Page */}
                  {translate("forgot_success_email_redirectText")}
                </strong>
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </div>
  );
}
CheckEmail.propTypes = {};

export default CheckEmail;
