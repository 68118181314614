import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

/* Table Styling Starts Here */
export const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      fontSize: "0.75rem !important",
      "&:nth-child(1)": {
        width: "40%",
      },
      "&:nth-child(3)": {
        width: "18%",
      },
      "&:nth-child(4)": {},
    },
  },
  head: {
    backgroundColor: "#30375B",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "0.9rem",
    lineHeight: "0.9rem",
    padding: "1rem 1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  body: {
    fontSize: "0.8rem",
    color: "#262626 !important",
    padding: "0.25rem 1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.25rem 0.75rem 0.25rem 0.5rem",
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "1rem 0 1rem 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0.35rem",
    },
  },
  onlyDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  onlyMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  onlyDesktopTableCell: {
    display: "table-cell",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  onlyMobileTableCell: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "table-cell",
    },
  },
  table: {
    minWidth: 700,
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      marginBottom: "0.5rem",
    },
    "& tbody": {
      borderLeft: "0.1px solid #ececec",
      borderRight: "0.1px solid #ececec",
    },
  },
  productName: {
    display: "table-cell",
    verticalAlign: "middle",
    paddingLeft: "2rem",
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.25rem",
    },
  },
  mediaPaddingLeftNone: {
    paddingLeft: "0rem !important",
  },
  open: {
    background: "#33A0FF",
    padding: "0.5rem",
    margin: "0",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  tableContainerStyle: {
    display: "block",
    borderRadius: "20px",
    marginBottom: "2rem",
    boxShadow: "0px 4px 10px #00000029",
    marginTop: "2rem",
    paddingRight: "0.1px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "96%",
      margin: "1rem auto 2rem auto",
    },
  },
  commentMobile: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem !important",
    },
  },
  poNumberHeading: {
    color: "#0076B3",
    margin: "1rem",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.5rem",
    width: "100%",
    padding: "0.5rem 1rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  mediaHolder: {
    width: 50,
    height: 50,
    marginRight: "1rem",
    display: "table-cell",
    backgroundImage: "url(./product/transparent/Product_BG.png)",
    backgroundSize: "cover",
    backgroundPosition: 0,
    borderRadius: "0.5rem",
    [theme.breakpoints.down("xl")]: {
      // height: "200px",
    },
    [theme.breakpoints.down("lg")]: {
      // height: "140px",
    },
    [theme.breakpoints.down("md")]: {
      // height: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.25rem",
      minWidth: 50,
      backgroundSize: "50px 50px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
    },
  },
  mediaTable: {
    backgroundSize: "contain",
    objectFit: "cover",
    height: "50px",
    width: "50px",
  },
  shippingPrice: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  shippingTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  commentsTitle: {
    fontSize: "0.95rem",
    fontWeight: "bold",
  },
  commentsTitleDesc: {
    fontSize: "0.8rem",
  },
  taxPrice: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#0076B3",
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.85rem",
    },
  },
  taxTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.85rem",
    },
  },
  totalPrice: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#0076B3",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.15rem",
    },
  },
  totalTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1rem",
    },
  },
  warning: {
    background: "#f0ad4e",
    color: "#ffffff",
    padding: "0.5rem",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  danger: {
    background: "#d9534f",
    padding: "0.5rem",
    color: "#ffffff",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  success: {
    background: "#5cb85c",
    padding: "0.5rem",
    color: "#ffffff",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  info: {
    background: "#5bc0de",
    padding: "0.5rem",
    color: "#ffffff",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  detailTableHead: {
    "& th:first-child": {
      borderRadius: "1.25rem 0 0 0",
    },
    "& th:last-child": {
      borderRadius: "0 1.25rem 0 0",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
  headerGridContainer: {
    marginBottom: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "96%",
    },
    "& paper": {},
  },
  paperHeaderGrid: {
    borderRadius: 0,
    background: "transparent",
    boxShadow: "none",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      flexDirection: "column",
      alignItems: "stretch",
      padding: "1px",
    },
  },
  paperHeaderGridLeft: { justifyContent: "start", position: "relative" },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      display: "block",
      padding: "1rem",
    },
  },
  titleSummary: {
    color: "#535353",
    fontSize: "1.5rem",
    margin: "0",
    paddingLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },  
  whiteBtn: {
    color: "#0076B3",
    border: "1px solid #0076B3",
    background: "#fff",
    borderRadius: 0,
    whiteSpace: "nowrap",
    cursor: "pointer",
    fontWeight: "bold",
    maxHeight: "40px",
    padding: "0.5rem 1rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    fontSize: "0.75rem",
    "&:hover": {
      backgroundColor: "#0076b3",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      margin: "0.5rem 0",
    },
  },
  storeFilterBtn: {
    color: "#0076B3",
    border: "1px solid #0076B3",
    background: "#fff",
    borderRadius: 0,
    whiteSpace: "nowrap",
    cursor: "pointer",
    fontWeight: "bold",
    maxHeight: "40px",
    padding: "0.2rem 1.25rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    fontSize: "0.75rem",
    "&:hover": {
      backgroundColor: "#0076b3",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      margin: "0.5rem 0",
    },
  },
  acceptBtn: {
    color: "#11D301"
  },
  rejectBtn: {
    color: "#ff4b4b"
  },  
  orderDetailRow: {
    textAlign: "left",
    margin: "6px 0 7px 0",
  },
  orderDetailTitle: {
    textAlign: "left",
    fontSize: "0.9rem",
    fontWeight: "bold",
    margin: "0",
    color: "#333333",
  },
  orderDetailLabel: {
    textAlign: "left",
    fontSize: "0.8rem",
    minWidth: "9rem",
    display: "inline-block",
    color: "#333333",
  },
  orderDetailLabelValue: {
    textAlign: "left",
    fontSize: "0.8rem",
    color: "#333333",
  },
  buttonStyle: {
    textAlign: "right",
    marginTop: "1.75rem",
    marginBottom: "1.5rem",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
  headerGridRightMobile: {
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0)",
      transformOrigin: 0,
      transition: "0.25s ease all",
      display: "none",
      opacity: "0",
      transitionDuration: "0.5s",
      transitionTimingFunction: "ease-in",
      transitionDelay: "0.3s",
    },
  },
  headerGridRightMobileActive: {
    [theme.breakpoints.down("sm")]: {
      transform: "scale(1)",
      display: "block",
      opacity: "1",
    },
  },
  toggleMobile: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 0,
      width: 100,
      top: 0,
      border: "none",
      paddingRight: 0,
    },
  },
  BtnTransparentBG: {
    borderRadius: "0",
    height: "100%",
    padding: 0,
    "&:active": {
      backgroundColor: "transparent !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.active": {
      backgroundColor: "#transparent !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#transparent !important",
      "& svg": {
        fill: "#fff",
        paddingTOp: "0.6rem",
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0px 4px 10px #00000029",
    borderRadius: "1.25rem",
    border: "0.1px solid #ececec",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    "& >:nth-child(1)": {
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
  },
  formContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      alignItems: "stretch",
      flexDirection: "column",
    },
  },
  textField: {
    "& > *": {
      padding: "0 !important",
      borderRadius: 0,
      lineHeight: "0.25rem",
      color: "#707070",
      fontSize: "0.8rem",
    },
  },
  formControl: {
    margin: "0.25rem 0.35rem",
    width: 130,
    [theme.breakpoints.between("sm", "md")]: {
      minWidth: 100,
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
    "& > *": {
      marginTop: "1px !important",
      border: "1px solid #707070",
      background: "#fff",
      fontSize: "0.8rem",
      [theme.breakpoints.between("sm", "md")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
      "& label + .MuiInput-formControl": {
        marginTop: "7px",
      },
      "& select": {
        height: "1.1rem",
        padding: "5px 5px 5px 6px",
        color: "#707070",
      },
      "& input": {
        height: "1rem",
        padding: "6px 0px 5px 4px",
        color: "#707070",
      },
      "& ::placeholder": {
        color: "#707070",
        opacity: "1",
      },
      "& :-ms-input-placeholder": {
        color: "#707070",
      },
      "& ::-ms-input-placeholder": {
        color: "#707070",
      },
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before":
      {
        borderBottom: "none",
      },
  },
}));