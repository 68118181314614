import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { cartItemsTotalCountSelector } from "../../../store/selectors/cartItems.selector";
import { colors } from "../../../styles/variables";
import SingleColumn from "../../styled/SingleColumn.styled";
import NavList from "../header/NavList";

const NewHeader = ({ appHeaderAPI }) => {
  const data = appHeaderAPI;
  const [open, setOpen] = useState(false);

  const totalCountCartItems = useSelector(cartItemsTotalCountSelector);

  if (data) {
    return (
      <HeaderHolder>
        <SingleColumn>
          <Nav>
            <Logo to="/">
              <img src={data.logo} alt="PepsiCo"></img>
            </Logo>

            <CartIcon to="/cart-preview">
              <ShoppingCartIcon />
              <DivCart>
                <SpanCart>{totalCountCartItems}</SpanCart>
              </DivCart>
            </CartIcon>

            <StyledItem open={open} onClick={() => setOpen(!open)}>
              <div />
              <div />
              <div />
            </StyledItem>

            <NavList isOpen={open} navData={data} />
          </Nav>
        </SingleColumn>
      </HeaderHolder>
    );
  } else {
    return null;
  }
};

NewHeader.defaultPropTypes = {};

NewHeader.propTypes = {
  appHeaderAPI: PropTypes.object.isRequired,
};

export default NewHeader;

const HeaderHolder = styled.div`
  z-index: 1;
  box-shadow: 0px 6px 3px #00000029;
  position: sticky;
  top: 0;
  background-color: #fafafa;

  @media (max-width: 768px) {
    & > * {
      padding: 0.25rem 0 !important;
    }
  }
`;
const Nav = styled.nav`
  width: 100%;
  background: #fafafa;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;
const Logo = styled(NavLink)`
  padding: 0;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 360px) {
    margin-left: 1.85rem;
  }
  img {
    width: 150px;
    padding: 0.25rem;
    margin-right: 1rem;
    @media (max-width: 950px) {
      width: 160px;
      margin-right: 0;
    }
    @media (max-width: 768px) {
      width: auto;
      max-width: 60%;
    }
  }
`;

const CartIcon = styled(NavLink)`
  padding-top: 1.5rem;
  display: none;
  &.active {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    position: relative;
    display: block;
    color: ${colors.greyDark};
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const StyledItem = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 20;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    left: 1rem;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#ccc" : "#333")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const DivCart = styled.div`
  border-radius: 50%;
  display: inline-block;
  background: #ff4b4b;
  color: white;
  width: 25px;
  height: 25px;
  vertical-align: top;
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    right: 0;
  }
`;

const SpanCart = styled.span`
  line-height: 25px;
  display: block;
  color: white;
  vertical-align: top;
  text-align: center;
  font-size: 0.75rem;
`;
