import {
  CardActions,
  CardMedia,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { useContext } from "react";
import { cartPics } from "../../styles/variables";
import { getEnv } from "../../utils/urls";
import { getFormatedPrice } from "../common/helper/helper";
import { StyledTableCell, StyledTableRow, useStyles } from "./Wishlist.styles";
import { WishlistContext } from "./WishlistContext";

const WishlistDetails = ({ wishlistDataProvider }) => {
  const classes = useStyles();
  const isLayoutWithImage = getEnv().layoutWithImage;

  const {
    handelRemoveItem,
    handelRMAQty,
    handelQtyPlaceholder,
    handleUnitType,
  } = useContext(WishlistContext);

  if (!wishlistDataProvider) {
    return null;
  }
  return (
    <>
      <StyledTableRow key={wishlistDataProvider.id}>
        <StyledTableCell
          component="th"
          scope="row"
          className={classes.onlyDesktop}
        >
          {isLayoutWithImage == "true" && (
            <div
              className={classes.mediaHolder}
              style={{
                backgroundColor: `#${wishlistDataProvider.colourCode}`,
              }}
            >
              <CardMedia
                className={classes.mediaTable}
                image={wishlistDataProvider.image}
                title={wishlistDataProvider.materialName}
              />
            </div>
          )}
          <div
            className={
              isLayoutWithImage == "true"
                ? classes.mediaTableProductName
                : `${classes.mediaTableProductName} ${classes.mediaPaddingLeftNone}`
            }
          >
            <strong>{wishlistDataProvider.materialName}</strong>
          </div>
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.onlyDesktop}>
          {parseInt(wishlistDataProvider.materialCode)} <br />
        </StyledTableCell>
        {getEnv().displayBarCode === "true" && (
          <StyledTableCell className={classes.onlyDesktop} align="center">
            {wishlistDataProvider.barCode
              ? parseInt(wishlistDataProvider.barCode)
              : "-"}{" "}
            <br />
          </StyledTableCell>
        )}

        <StyledTableCell
          component="th"
          scope="row"
          className={classes.onlyMobile}
        >
          {isLayoutWithImage == "true" && (
            <div
              className={classes.mediaHolder}
              style={{
                backgroundColor: `#${wishlistDataProvider.colourCode}`,
              }}
            >
              <CardMedia
                className={classes.mediaTable}
                image={wishlistDataProvider.image}
                title={wishlistDataProvider.materialName}
              />
            </div>
          )}
          <div>
            <strong>{wishlistDataProvider.materialName}</strong>
          </div>
          {parseInt(wishlistDataProvider.materialCode)}
          <br />
          {wishlistDataProvider.barCode
            ? parseInt(wishlistDataProvider.barCode)
            : "-"}{" "}
          <br />
          {getEnv().displayUnitType === "true" && (
            <StyledTableCell align="left" className={classes.onlyMobile}>
              <NativeSelect
                onChange={(event) =>
                  handleUnitType(
                    event,
                    wishlistDataProvider.id,
                    wishlistDataProvider
                  )
                }
                inputProps={{
                  name: wishlistDataProvider.id + "-unitType-name",
                  id: wishlistDataProvider.id + "-unitType-id",
                }}
                className={classes.selectStyle}
              >
                {wishlistDataProvider.unitType
                  ? wishlistDataProvider.unitType.map(
                      (singleUnitType, index) => (
                        <option value={singleUnitType.key} key={index}>
                          {singleUnitType.label}
                        </option>
                      )
                    )
                  : null}
              </NativeSelect>
            </StyledTableCell>
          )}
          <br />
        </StyledTableCell>

        <StyledTableCell align="right" className={classes.onlyDesktopTableCell}>
          <CardActions>
            <div className={classes.productQuantityWrapper}>
              <TextField
                InputProps={{ inputProps: { min: 0 } }}
                id={"quantityId_" + wishlistDataProvider.materialCode}
                maxLength="3"
                autoComplete="off"
                className={classes.productQuantity}
                onChange={(e) =>
                  handelRMAQty(e, e.target.value, wishlistDataProvider)
                }
                onMouseDown={(e) =>
                  handelQtyPlaceholder(e.target.value, wishlistDataProvider)
                }
                onBlur={(e) =>
                  e.target.value == ""
                    ? handelRMAQty(e, e.target.value, wishlistDataProvider)
                    : null
                }
                value={wishlistDataProvider.count}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </CardActions>
        </StyledTableCell>

        <StyledTableCell align="right" className={classes.onlyMobileTableCell}>
          <CardActions>
            <div className={classes.productQuantityWrapper}>
              <TextField
                InputProps={{ inputProps: { min: 0 } }}
                id={"quantityId_" + wishlistDataProvider.materialCode}
                maxLength="3"
                autoComplete="off"
                className={classes.productQuantity}
                onChange={(e) =>
                  handelRMAQty(e, e.target.value, wishlistDataProvider)
                }
                onMouseDown={(e) =>
                  handelQtyPlaceholder(e.target.value, wishlistDataProvider)
                }
                onBlur={(e) =>
                  e.target.value == ""
                    ? handelRMAQty(e, e.target.value, wishlistDataProvider)
                    : null
                }
                value={wishlistDataProvider.count}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </CardActions>
        </StyledTableCell>

        {getEnv().displayUnitType === "true" && (
          <StyledTableCell align="center" className={classes.onlyDesktop}>
            <NativeSelect
              onChange={(event) =>
                handleUnitType(
                  event,
                  wishlistDataProvider.id,
                  wishlistDataProvider
                )
              }
              inputProps={{
                name: wishlistDataProvider.id + "-unitType-name",
                id: wishlistDataProvider.id + "-unitType-id",
              }}
              className={classes.selectStyle}
            >
              {wishlistDataProvider.unitType
                ? wishlistDataProvider.unitType.map((singleUnitType, index) => (
                    <option value={singleUnitType.key} key={index}>
                      {singleUnitType.label}
                    </option>
                  ))
                : null}
            </NativeSelect>
          </StyledTableCell>
        )}

        <StyledTableCell align="right">
          <div className={classes.productQuantityWrapper}>
            {getEnv().currency}
            {wishlistDataProvider.unitType &&
              wishlistDataProvider.unitType
                .filter((type) => type.active === 1)
                .map((singleUnitType) => {
                  return getFormatedPrice(singleUnitType.price);
                })[0]}
          </div>
        </StyledTableCell>

        <StyledTableCell align="center">
          <img
            src={`${cartPics.shoppingIcon}`}
            className={classes.shoppingIcon}
            alt="cart"
            disabled={wishlistDataProvider.count > 0 ? false : true}
          />
        </StyledTableCell>

        <StyledTableCell align="center">
          <img
            onClick={() => {
              handelRemoveItem(wishlistDataProvider);
            }}
            src={`${cartPics.deleteIcon}`}
            className={classes.deleteIcon}
            alt="cart"
          />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default WishlistDetails;
