import {
  Button,
  ClickAwayListener,
  FormControl,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PropTypes from "prop-types";
import * as React from "react";
import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { LocalContext } from "../../../LocalProvider.js";
import LoginPage from "../../../pages/Login.page";
import { moveCartItemsToCartAction } from "../../../store/actions/cart.actions.js";
import {
  clearCartItemsDataAction,
  getCartItemsDataAction,
} from "../../../store/actions/cartItems.actions";
import { changeLanguageAction } from "../../../store/actions/language.action.js";
import {
  clearArchiveDataAction,
  clearReduxDataAction,
  fetchGetCheckSupervisor,
  fetchGetNavigation,
  fetchLogout,
  getCustomerArchiveStatus,
} from "../../../store/actions/navItems.actions";
import {
  cartItemsTotalCountSelector,
  getCartOrderTypeSelector,
} from "../../../store/selectors/cartItems.selector";
import {
  allHeaderData,
  getAssociatedCustomers,
  getChangePasswordRequired,
  getCustomerArchiveData,
  getHeaderToken,
  getUserType,
  isAuthenticated,
} from "../../../store/selectors/navItems.selector";
import { colors, mediaQueries } from "../../../styles/variables";
import { apiHeaderWithSiteId, getEnv } from "../../../utils/urls";
import ProfileDialog from "../../common/ProfileDialog";
import AlertDialog from "../AlertDialog";
import {
  getSelectedAssociatedCustomer,
  getSelectedAssociatedCustomerCreditAmt,
  getSelectedAssociatedCustomerType,
  getDefaultRoutes
} from "../helper/helper";

const RightNav = ({ isOpen, navData }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [profileValue, setProfileValue] = useState(
    getSelectedAssociatedCustomerType() || null
  );
  const [navigationData, setNavigationData] = useState([]);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isCheckSupervisorCall, setIsCheckSupervisorCall] = useState(false);
  const [open, setOpen] = useState(false);
  const [openOnTouch, setOpenOnTouch] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [customerArchiveAlert, setCustomerArchiveAlert] = useState(false);
  const [customerArchiveAlertMessages, setCustomerArchiveAlertMessages] = useState(false);
  const [customerArchiveCurrentMessage, setCustomerArchiveCurrentMessage] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const changePasswordRequired = useSelector(getChangePasswordRequired);
  const [language, setLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const { translate } = useContext(LocalContext);

  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const token = useSelector(getHeaderToken);
  const isAuth = useSelector(isAuthenticated);
  const associatedCustomers = useSelector(getAssociatedCustomers);
  const userTypeData = useSelector(getUserType);
  const orderTypeCart = useSelector(getCartOrderTypeSelector);
  const customerArchiveData = useSelector(getCustomerArchiveData);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const totalCountCartItems = useSelector(cartItemsTotalCountSelector);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const headerData = useSelector(allHeaderData);
  const isUserAuth = headerData;
  const [creditAmount, setCreditAmount] = useState(
    getSelectedAssociatedCustomerCreditAmt()
  );
  const defaultRoutes = getDefaultRoutes() || [];
  var selectedCustomerCreditAmt = localStorage.getItem("selectedCustomerCreditAmt");

  useEffect(() => {
    if (location.pathname.includes("products")) {
      setSelectedMenu(1);
    } else if (
      location.pathname.includes("order") ||
      location.pathname.includes("buy")
    ) {
      setSelectedMenu(2);
    } else if(location.pathname.includes("projectList")) {
      setSelectedMenu(3);
    }
  }, [location.pathname]);

  const getArchieveMessages = (archiveData) => {
    const tempMessages = [];
  
    if(archiveData?.archiveBox?.hasOwnProperty("msg")) {
      tempMessages.push({'header': 'archiveBox', 'msg': archiveData?.archiveBox?.msg });
    }

    if(archiveData?.contract?.hasOwnProperty("msg")) {
      tempMessages.push({'header': 'contract', 'msg': archiveData?.contract?.msg });
    }

    if(archiveData?.estatement?.hasOwnProperty("msg")) {
      tempMessages.push({'header': 'estatement', 'msg': archiveData?.estatement?.msg });
    }

    if(archiveData?.idtPromotionMsg?.hasOwnProperty("msg")) {
      tempMessages.push({'header': 'idtPromotionMsg', 'msg': archiveData?.idtPromotionMsg?.msg });
    }
    setCustomerArchiveAlertMessages(tempMessages);
    if(tempMessages?.length > 0) {
      setCustomerArchiveAlert(true);
    }
  }

  // Show Next Archive Message In Separate Popup
  const showNextArchieveMessage = () => {
    if(customerArchiveAlertMessages?.length > 0) {
      const nextMessage = customerArchiveAlertMessages.shift();
      setCustomerArchiveCurrentMessage(nextMessage.msg);
      setCustomerArchiveAlertMessages([...customerArchiveAlertMessages]);
    } else {
      setCustomerArchiveCurrentMessage(null);
      handleCloseArchiveAlert();
    }
  }

  // Initially Show First Archieve Message
  useEffect(() => {
    if(customerArchiveAlertMessages && customerArchiveAlert && customerArchiveCurrentMessage === null) {
      showNextArchieveMessage();
    }
  }, [customerArchiveAlertMessages, customerArchiveCurrentMessage, customerArchiveAlert])

  useEffect(() => {
    if(isAuth && customerArchiveData) {
     getArchieveMessages(customerArchiveData);
    }
  }, [token, isAuth, customerArchiveData]);

  useEffect(() => {
    selectedCustomerCreditAmt && setCreditAmount(getSelectedAssociatedCustomerCreditAmt());
  }, [selectedCustomerCreditAmt]);

  useEffect(() => {
    if (
      prevOpen.current === true &&
      open === false &&
      anchorRef !== null &&
      anchorRef.current !== null
    ) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const onChangeUsername = (event) => {
    // if (event.target.value !== "Change Customer") {
    //   setUsername(event.target.value);
    // }
  };

  // Toggle menu header when click
  const menuToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onClickMenu = (event) => {
    event.preventDefault();
  };

  //Mobile Toggle menu header when click
  const onTouchMenuToggle = (key) => {
    setSelectedLink(key);
    setOpenOnTouch((openOnTouch) => !openOnTouch);
  };

  const onClickSubMenu = (event) => {
    const key = event.currentTarget.getAttribute("id");
    const index = key.split("-")[1];
    if (key.includes("shop")) {
      setSelectedMenu(1);
    }
    if (key.includes("order")) {
      setSelectedMenu(2);
    }
    if (key.includes("projectList")) {
      setSelectedMenu(3);
    }
  };

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const onClickSignIn = () => {
    let toggle = openSignIn ? false : true;
    setOpenSignIn(toggle);
  };

  const handleCloseSignIn = () => {
    setOpenSignIn(false);
  };

  const handleLogout = (event) => {
    localStorage.removeItem("selectedCustomerID");
    localStorage.removeItem("selectedCustomerType");
    localStorage.removeItem("selectedCustomerSapId");
    localStorage.removeItem("selectedCustomerCreditAmt");
    localStorage.removeItem("persist:root");
    localStorage.clear();
    if (apiHeaderWithSiteId["AssociatedCustomer"]) {
      delete apiHeaderWithSiteId["AssociatedCustomer"];
    }
    dispatch(fetchLogout(token));
  };

  const handlecloseprofile = (selectedCustomerId) => {
    if (selectedCustomerId && selectedCustomerId !== null) {
      setSelectedId(selectedCustomerId);
      setProfileValue(getSelectedAssociatedCustomerType());
    }
    setProfileDialogOpen(false);
    dispatch(clearCartItemsDataAction(token)).then(() => {
      dispatch(fetchGetNavigation());
      dispatch(getCustomerArchiveStatus(token)).then((data) => {
        if (
          isAuth &&
          data !== undefined) {
          getArchieveMessages(data);
        } else {
          dispatch(clearReduxDataAction(token));
          if (changePasswordRequired) {
            history.push("/change-password?changepwd=1");
          } else {
            history.push("/home");
          }
        }
      });
      dispatch(getCartItemsDataAction(token)).then(() => {
        dispatch(moveCartItemsToCartAction(orderTypeCart));
      });
    });
  };

  const handleChangeCustomer = () => {
    setCustomerArchiveAlert(false);
    var selectedCustomerID = getSelectedAssociatedCustomer();
    if (
      selectedCustomerID &&
      selectedCustomerID !== null &&
      associatedCustomers &&
      associatedCustomers.length > 1 &&
      profileDialogOpen === false
    ) {
      setOpenAlert(true);
    }
    dispatch(fetchGetCheckSupervisor(token, isAuth));
  };

  const handleAgreeClick = () => {
    setOpenAlert(false);
    setProfileDialogOpen(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setProfileDialogOpen(false);
  };

  const handleCloseArchiveAlert = () => {
    if(customerArchiveAlertMessages?.length > 1) {
      setCustomerArchiveAlert(true);  
    } else {
      setCustomerArchiveAlert(false);
      if (associatedCustomers) {
        dispatch(clearReduxDataAction(token));
      }
      dispatch(clearArchiveDataAction(token));
      if (changePasswordRequired) {
        history.push("/change-password?changepwd=1");
      } else {
        history.push("/home");
      }
    }
  };

  const handleMyAccount = () => {
    history.push("/my-account");
  };

  const handleChangePassword = () => {
    history.push("/change-password?changepwd=1");
  };

  const handleChangeLanguageCN = (event) => {
    setLanguage(event.target.value);

    localStorage.setItem("language", event.target.value);
    dispatch(changeLanguageAction(event.target.value));
    dispatch(fetchGetNavigation());
    // history.replace(`/?path=${history.location.pathname}`);
  };

  const leftNavigation = useCallback((userTypeData) => {
    switch (userTypeData) {
      case "Payer":
        return navData.leftNavigation.Payer;

      case "MT":
        return navData.leftNavigation.MT;

      case "Sold_To_Party":
        return navData.leftNavigation.Sold_To_Party;
      
      case "BNCMulti":
        return navData.leftNavigation.BNCMulti;

      case "Profile":
        if (profileValue === "Payer") {
          return navData.leftNavigation.Payer;
        }
        if (profileValue === "Sold_To_Party") {
          return navData.leftNavigation.Sold_To_Party;
        }
        if (profileValue === "MT") {
          return navData.leftNavigation.MT;
        }
        if (profileValue === "Profile") {
          return navData.leftNavigation.Profile;
        }
        if (profileValue === "BNCMulti") {
          return navData.leftNavigation.BNCMulti;
        }
        break;

      default:
        return navData.leftNavigation;
    }
  }, [navData.leftNavigation, profileValue]);

  useEffect(() => {
    const menuData = leftNavigation(userTypeData);
    // Filter out routes accessible to the user based on the menu data
    const allowedRoutes = menuData?.flatMap(singleNav => singleNav.subMenu || []).filter(singleSubMenu => location.pathname === `/${singleSubMenu.key}`) || [];

    const mergedRoutes = [...allowedRoutes, ...defaultRoutes];
    // If there are allowed routes for the current location, update the navigation data
    if (mergedRoutes?.length > 0 && mergedRoutes?.some((item) => location.pathname.includes(item.key))) {
      setNavigationData(menuData);
    } else {
      // If there are no allowed routes, redirect the user to a default route
      history.push("/home")
    }
  }, [userTypeData, navData, leftNavigation]);

  useEffect(() => {
    var selectedCustomerID = getSelectedAssociatedCustomer();
    var selectedCustomerType = getSelectedAssociatedCustomerType();
    if (!associatedCustomers && !isCheckSupervisorCall) {
      dispatch(fetchGetCheckSupervisor());
      setIsCheckSupervisorCall(true);
    }
    if (associatedCustomers && associatedCustomers.length === 1) {
      localStorage.setItem("selectedCustomerID", associatedCustomers[0].id);
      localStorage.setItem("selectedCustomerType", associatedCustomers[0].type);
      setProfileValue(associatedCustomers[0].type);
      localStorage.setItem(
        "selectedCustomerSapId",
        associatedCustomers[0].sapCode
      );
      localStorage.setItem(
        "selectedCustomerCreditAmt",
        associatedCustomers[0].creditBalance
      );
    } else if (
      associatedCustomers &&
      associatedCustomers.length > 1 &&
      selectedId === "" &&
      profileDialogOpen === false &&
      (selectedCustomerID === undefined || selectedCustomerID === null) &&
      (selectedCustomerType === undefined || selectedCustomerType === null)
    ) {
      setProfileDialogOpen(true);
    }
  }, [associatedCustomers, dispatch, isCheckSupervisorCall, profileDialogOpen, selectedId]);

  useEffect(() => {
    setNavigationData(leftNavigation(userTypeData));
  }, [profileValue, leftNavigation, userTypeData]);

  return (    
    <MenuHolder open={isOpen}>
      {/* ALERT POPUP FOR CHANGE CUSTOMER */}
      {openAlert && (
        <AlertDialog
          open={openAlert}
          handleClose={() => handleCloseAlert()}
          handleAgreeClick={() => handleAgreeClick()}
          headerMessage={translate(
            "Are you sure that you would like to change the customer?"
          )}
          contentMessage={translate(
            "Your current cart will be cleared, You won't be able to undo the changes done!"
          )}
          yesBtnLabel={translate("Continue")}
          noBtnLabel={translate("Cancel")}
        />
      )}

      {/* If associatedCustomer are more that one */}
      {profileDialogOpen && (
        <ProfileDialog
          open={profileDialogOpen}
          handlecloseprofile={() => handlecloseprofile}
          title={translate("Choose a Customer")}
          dropdowndata={associatedCustomers}
        />
      )}

      {customerArchiveCurrentMessage && (
        <AlertDialog
          open={customerArchiveAlert}
          handleClose={() => showNextArchieveMessage()}
          contentMessage={
            <div>
              {customerArchiveCurrentMessage && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: customerArchiveCurrentMessage,
                  }}
                ></p>
              )}
            </div>
          }
          noBtnLabel={translate("StatusOk")}
        />
      )}

      {!changePasswordRequired && (
        <LeftItem>
          {navigationData &&
            navigationData?.map((headerDataAPI, key) => (
              <div key={key}>
                {headerDataAPI?.subMenu === undefined && (
                  <>
                    <li className={classes.homeLink}>
                      <HeaderLink to={"/" + headerDataAPI?.key} exact>
                        <strong>{headerDataAPI?.link}</strong>
                      </HeaderLink>
                    </li>
                  </>
                )}
                {headerDataAPI?.subMenu !== undefined && (
                  <>
                    <li>
                      <ButtonSty
                        selecttab={selectedMenu == key ? "test" : ""}
                        hoverontouch={selectedLink == key && openOnTouch}
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={onClickMenu}
                        onMouseOver={menuToggle}
                        onTouchStart={() => onTouchMenuToggle(key)}
                        to={"/" + headerDataAPI?.key}
                      >
                        <strong>{headerDataAPI?.link}</strong>
                      </ButtonSty>
                      <PopperItem
                        open="true"
                        className={
                          selectedLink == key && openOnTouch
                            ? classes.mobilePopper
                            : ""
                        }
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                              padding: "0 5px 0 5px",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {headerDataAPI?.subMenu?.map(
                                    (userdata, key) => (
                                      <SubHeaderLink
                                        to={"/" + userdata.key}
                                        key={key}
                                        id={headerDataAPI?.key + "-" + key}
                                        onClick={onClickSubMenu}
                                      >
                                        {userdata.link}
                                      </SubHeaderLink>
                                    )
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </PopperItem>
                    </li>
                  </>
                )}
              </div>
            ))}
        </LeftItem>
      )}

      {!changePasswordRequired && (
        <RightItem>
          {/* Bestsign for China */}
          {getEnv().siteId === "1" &&
            navData && navData.middleNavigation && navData.middleNavigation.map((userdata, key) => (
              <div className="bestsign">
                <span
                  key={key}
                  dangerouslySetInnerHTML={{
                    __html: userdata.link,
                  }}
                />
              </div>
            ))}
          {/* Language Selector for China */}
          {getEnv().siteId === "1" && (
            <FormControl className={classes.formControl}>
              <Select
                value={language}
                onChange={handleChangeLanguageCN}
                displayEmpty
                className={classes.marginTopUserName}
              >
                <MenuItem className={classes.menuItemStyle} value="zh">
                  <strong className={classes.loggedInItemsLabel}>中文</strong>
                </MenuItem>
                <MenuItem className={classes.menuItemStyle} value="en">
                  <strong className={classes.loggedInItemsLabel}>
                    English
                  </strong>
                </MenuItem>
              </Select>
            </FormControl>
          )}

          {!isUserAuth.isAuth && (
            <>
              <HeaderNavItem>
                <HeaderButton onClick={() => onClickSignIn()}>
                  {navData.rightNavigation.map((userdata, key) => (
                    <strong key={key}>{userdata.link}</strong>
                  ))}
                </HeaderButton>
              </HeaderNavItem>
            </>
          )}

          {openSignIn && <LoginPage handleCloseSignIn={handleCloseSignIn} />}
          {isUserAuth.isAuth === true && (
            <>
              <div className={classes.creditAmount}>
                {translate("Credit Amount")} :{" "}
                <span className={classes.creditAmountText}>
                  {getEnv().currency}
                  {creditAmount}
                </span>
              </div>
              <FormControl className={classes.formControl}>
                <Select
                  value={username}
                  onChange={onChangeUsername}
                  displayEmpty
                  className={classes.marginTopUserName}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled className={classes.menuItemStyle}>
                    <strong className={classes.loggedInItemsLabel}>
                      {navData.userName}
                    </strong>
                  </MenuItem>
                  {navData.rightNavigation.map((userdata, key) => (
                    <MenuItem
                      key={key}
                      value={userdata.link}
                      className={classes.menuItemStyle}
                    >
                      {(userdata.key === "Logout" ||
                        userdata.key === "logout") && (
                        <div
                          className={classes.loggedInItems}
                          onClick={() => handleLogout()}
                        >
                          {userdata.link}
                        </div>
                      )}

                      {(userdata.key === "Change Password" ||
                        userdata.key === "change-password") && (
                        <div
                          className={classes.loggedInItems}
                          onClick={() => handleChangePassword()}
                        >
                          {userdata.link}
                        </div>
                      )}

                      {(userdata.key === "My Account" ||
                        userdata.key === "my-account") && (
                        <div
                          className={classes.loggedInItems}
                          onClick={() => handleMyAccount()}
                        >
                          {userdata.link}
                        </div>
                      )}

                      {(userdata.key === "Change Customer" ||
                        userdata.key === "change-customer") && (
                        <div
                          className={classes.loggedInItems}
                          onClick={() => handleChangeCustomer()}
                        >
                          {userdata.link}
                        </div>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(
                <HeaderNavItemCart>
                  <HeaderLink to="/cart-preview">
                    <ShoppingCartIconStyle />
                    <DivCart>
                      <SpanCart>
                        {isNaN(totalCountCartItems) === true
                          ? 0
                          : totalCountCartItems}
                      </SpanCart>
                    </DivCart>
                  </HeaderLink>
                </HeaderNavItemCart>
              )}
            </>
          )}
        </RightItem>
      )}
    </MenuHolder>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      "& li": {
        padding: "0 10px",
      },
    },
    formControl: {
      margin: theme.spacing(0.5),
      minWidth: 90,
      "& > div": {
        backgroundColor: "#fafafa",
        "&::before": {
          borderBottom: "none",
          "&::focus": {
            backgroundColor: colors.red,
          },
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    menuItemStyle: {
      padding: 0,
    },
    loggedInItems: {
      color: "grey",
      textDecoration: "none",
      width: "100%",
      padding: "0.5rem",
      "&:hover": {
        color: "black",
      },
      "&:active": {
        color: "black",
      },
      "&:focus": {
        color: "black",
      },
    },
    loggedInItemsLabel: {
      width: "100%",
      padding: "0.5rem",
    },
    marginTopUserName: {
      marginTop: "1.5rem",
      fontSize: "1.25rem",
      [theme.breakpoints.down("xl")]: {
        fontSize: "1rem",
      },
      "@media (max-width: 1366px)": {
        fontSize: "0.85rem",
        marginTop: "1.6rem",
      },
      [theme.breakpoints.down(768)]: {
        fontSize: "1.25rem",
      },
      "& svg": {
        fontSize: "2.1rem",
        top: "calc(50% - 15px)",
      },
    },
    select: {
      "& option": {
        backgroundColor: "red",
        color: "red",
      },
    },
    [theme.breakpoints.down(768)]: {
      marginTopUserName: {
        color: colors.blue,
      },
      formControl: {
        marginLeft: theme.spacing(2),
      },
    },
    creditAmount: {
      marginTop: "1.75rem",
      // marginRight: "0.5rem",
      backgroundColor: "#F1F3F4",
      borderRadius: "1rem",
      fontSize: "0.85rem",
      fontWeight: "bold",
      lineHeight: "1rem",
      maxHeight: "30px",
      padding: "0.5rem",
      "@media (max-width: 1366px)": {},
      "@media (max-width: 950px)": {
        display: "none",
      },
    },
    creditAmountText: {
      color: "#11D301",
      fontWeight: "normal",
    },
    homeLink: {
      paddingRight: "0rem !important",
    },
    mobilePopper: {
      [theme.breakpoints.down("sm")]: {
        display: "block !important",
      },
    },
  }),
  { name: "MuiMenu" }
);

const LeftItem = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 1.25rem 0 0 0;
  flex-flow: row nowrap;
  li {
    padding: 22px 10px;
    position: relative;
    @media ${mediaQueries.belowiPad} {
      display: inline-block;
    }
  }
  @media (max-width: 768px) {
    display: block;
    li {
      padding: 7px;
    }
  }
`;

const RightItem = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0.2rem 0;
  flex-flow: row nowrap;
  font-size: 1rem;
  li {
    padding: 22px 7px;
  }
  & div.bestsign {
    padding: 2rem 2rem 0 2rem;
  }
  @media (max-width: 760px) {
    display: block;
    li {
      padding: 7px;
    }
  }
`;

const MenuHolder = styled.div`
  list-style: none;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: inherit;
  & > div {
    display: none;
  }
  li {
    padding: 1rem 1.3rem 0 2rem;
    font-size: 1.25rem;
    @media ${mediaQueries.belowContainer} {
      font-size: 1rem;
    }
    @media ${mediaQueries.belowContainerStandard} {
      font-size: 0.85rem;
    }
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
    & > div {
      display: none;
      & > div {
        border-radius: 0;
        box-shadow: none;
      }
    }
    &:hover {
      @media ${mediaQueries.mobileAndAbove} {
        & > div {
          display: block;
        }
      }
    }
  }
  @media (max-width: 950px) {
    li {
      padding: 10px 14px;
    }
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    position: ${({ open }) => (open ? "absolute" : "fixed")};
    top: 100;
    right: 0;
    height: auto;
    maxwidth: 100%;
    margin-top: 3.45rem;
    transition: transform 0.3s ease-in-out;
    background: ${colors.white};
    z-index: 1;
    li {
      padding: 10px 20px;
    }
  }
`;

const HeaderNavItem = styled.li`
  float: left;
  text-align: center;
  padding-top: 1.9rem !important;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  @media (max-width: 768px) {
    float: none;
    padding: 0;
    text-align: left;
  }
`;

const HeaderNavItemCart = styled.li`
  float: left;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  @media (max-width: 768px) {
    float: none;
    padding: 0;
    text-align: left;
  }
`;

const HeaderLink = styled(NavLink)`
  color: ${colors.greyDark};
  text-decoration: none;
  position: relative;
  text-transform: capitalize;
  display: inline-block;
  font-size: 1.25rem;
  @media ${mediaQueries.belowContainer} {
    font-size: 1rem;
  }
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 0.85rem;
  }
  &:hover {
    color: ${colors.greyDark};
  }
  &.active {
    border-bottom: 2px solid red;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    color: ${colors.blue};
    font-size: 1.25rem;
  }
`;

const SubHeaderLink = styled(NavLink)`
  color: ${colors.greyDark};
  text-decoration: none;
  position: relative;
  text-transform: capitalize;
  display: block;
  font-size: 1.25rem;
  padding: 0.25rem 1rem 0.25rem 1.3rem;
  width: max-content;
  @media ${mediaQueries.belowContainer} {
    font-size: 0.85rem;
  }
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 0.7rem;
  }
  &:hover {
    color: ${colors.lightGrey};
  }
  &.active {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    color: ${colors.blue};
    font-size: 1.25rem;
  }
`;

const HeaderButton = styled(Button)`
  color: ${colors.greyDark};
  padding: 0px;
  text-transform: capitalize;
  font-size: 16px;
  &:hover {
    color: ${colors.greyDark};
    background-color: #fafafa;
  }
  @media (max-width: 768px) {
    color: ${colors.blue};
    font-size: 1.25rem;
  }
`;

const ButtonSty = styled(NavLink)`
  color: ${colors.greyDark};
  text-decoration: none;
  position: relative;
  border-bottom: ${(props) =>
    props.selecttab === "test" ? "2px solid red" : "none"};

  &:after {
    color: rgba(0, 0, 0, 0.54);
    content: url(./icons/arrowIcon.svg);
    position: absolute;
    top: calc(50% - 16px);
    font-size: 2.1rem;
    opacity: 0.55;
  }
  &:hover {
    @media ${mediaQueries.mobileAndAbove} {
      color: ${colors.lightGrey};
      &:after {
        transition: 0.9s;
        top: calc(50% - 16px);
        content: url(./icons/arrowIconUp.svg);
      }
    }
  }
  @media (max-width: 768px) {
    color: ${(props) => (props.hoverontouch ? colors.lightGrey : colors.blue)};
    font-size: 1.25rem;
    &:after {
      content: ${(props) =>
        props.hoverontouch ? "url(./icons/arrowIconUp.svg)" : ""};
    }
  }
`;
const PopperItem = styled(Popper)`
  z-index: 1;
  transform: none !important;
  top: 39px !important;
  left: 0 !important;
  width: auto;
  margin-left: 15px;
  @media (min-width: 1366px) {
    top: 38px !important;
  }
`;

const ShoppingCartIconStyle = styled(ShoppingCartIcon)`
  margin-top: 0.8rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const DivCart = styled.div`
  border-radius: 50%;
  display: inline-block;
  background: #ff4b4b;
  color: white;
  width: 25px;
  height: 25px;
  vertical-align: top;
  position: absolute;
  top: -6px;
  right: -14px;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const SpanCart = styled.span`
  line-height: 25px;
  display: inline-block;
  color: white;
  vertical-align: top;
  text-align: center;
  font-size: 0.75rem;
`;

RightNav.defaultPropTypes = {};

RightNav.propTypes = {
  navData: PropTypes.object.isRequired,
};

export default RightNav;
