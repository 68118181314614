import { getSelectedAssociatedCustomer } from "../../components/common/helper/helper";
import {
  apiHeaderWithSiteId,
  productBrandsAPIUrl,
  productSubBrandsAPIUrl,
} from "../../utils/urls";
import {
  getHeaderToken,
  isAuthenticated,
} from "../selectors/navItems.selector";
import { loadingEnd, loadingStart } from "./root.actions";

/* 
1> Update store with user action data (Sync)
2> Update the backend with user action data (Async)
3> Get the latest cart data on page load. (Async)

*/
export const brandSubBrandActionTypes = {
  FETCH_BRANDSUBBRAND_DATA_SUCCESS: "FETCH_BRANDSUBBRAND_DATA_SUCCESS",
  FETCH_BRANDSUBBRAND_DATA_FAILURE: "FETCH_BRANDSUBBRAND_DATA_FAILURE",
  FETCH_SUBBRAND_DATA_SUCCESS: "FETCH_SUBBRAND_DATA_SUCCESS",
  FETCH_SUBBRAND_DATA_FAILURE: "FETCH_SUBBRAND_DATA_FAILURE",
};

const fetchBrandSubBrandDataSuccessAction = (data) => {
  return {
    type: brandSubBrandActionTypes.FETCH_BRANDSUBBRAND_DATA_SUCCESS,
    data,
  };
};

const fetchSubBrandDataSuccessAction = (data) => {
  return {
    type: brandSubBrandActionTypes.FETCH_SUBBRAND_DATA_SUCCESS,
    data,
  };
};

export const fetchBrandDataAction =
  (payload = {}, isAuth, token) =>
  async (dispatch, getState) => {
    //  console.log("loading start");
    const latestState = getState();
    isAuth = isAuthenticated(latestState);
    token = getHeaderToken(latestState);
    let brandAPIData = { ...latestState.brandSubBrand.brand };
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    return await fetch(productBrandsAPIUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((response) =>
        response.json().then((data) => {
          brandAPIData = data;
          dispatch(fetchBrandSubBrandDataSuccessAction(brandAPIData, isAuth));
          dispatch(loadingEnd());
        })
      )
      .catch((err) => console.log(err));

    //   TODO: need to be changed to actual API call implementation
    //   setTimeout is just for demonstration purposes
    // setTimeout(() => {
    //   dispatch(loadingEnd());
    //   // console.log("loading end");
    //   // console.log(productData);
    //   dispatch(fetchBrandSubBrandDataSuccessAction(brandSubBrandData));
    // }, 500);
  };

export const fetchSubBrandDataAction =
  (payload, isAuth, token) => async (dispatch, getState) => {
    // console.log("loading start");
    const latestState = getState();
    let subBrandAPIData = { ...latestState.brandSubBrand.subBrand };
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    return await fetch(productSubBrandsAPIUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((response) =>
        response.json().then((data) => {
          subBrandAPIData = data;
          dispatch(fetchSubBrandDataSuccessAction(subBrandAPIData, isAuth));
          dispatch(loadingEnd());
          return data;
        })
      )
      .catch((err) => console.log(err));

    //   TODO: need to be changed to actual API call implementation
    //   setTimeout is just for demonstration purposes
    // setTimeout(() => {
    //   dispatch(loadingEnd());
    //   // console.log("loading end");
    //   // console.log(productData);
    //   dispatch(fetchBrandSubBrandDataSuccessAction(brandSubBrandData));
    // }, 500);
  };
