import { apiHeaderWithSiteId, homeAPIUrl } from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";
export const homePageActionTypes = {
  FETCH_HOMEPAGE_DATA_SUCCESS: "FETCH_HOMEPAGE_DATA_SUCCESS",
  FETCH_HOMEPAGE_DATA_FAILURE: "FETCH_HOMEPAGE_DATA_FAILURE",
};

const fetchHomePageDataSuccessAction = (data) => {
  return {
    type: homePageActionTypes.FETCH_HOMEPAGE_DATA_SUCCESS,
    data,
  };
};

export const fetchGetHome = (isAuth, token) => (dispatch) => {
  dispatch(loadingStart());
  // crate header as per isAuth
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(homeAPIUrl, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(fetchHomePageDataSuccessAction(data));
      return data;
    })
    .catch((err) => console.log(err));

  dispatch(loadingEnd());
};
