import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import "../../icon";
import { fetchGetNavigation } from "../../store/actions/navItems.actions";
// import Loading from "../common/Loading.component";
import {
  getFooterNavData,
  getHeaderNavData,
  getHeaderToken,
  isAuthenticated,
} from "../../store/selectors/navItems.selector";
import FontStyles from "../../styles/font-styles";
import GlobalStyles from "../../styles/global-styles";
import { getEnv } from "../../utils/urls";
import Footer from "../common/footer/Footer.component";
import Header from "../common/header/Header";
import Loading from "../common/Loading.component";
import Container from "../styled/Container.styled";
const StretchedMain = styled.main`
  flex: 1;
`;

const DefaultLayout = ({ children }) => {
  // Loading need to be add later
  const isAuth = useSelector(isAuthenticated);
  const headerData = useSelector(getHeaderNavData);
  const footerData = useSelector(getFooterNavData);
  const token = useSelector(getHeaderToken);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const selectedLang = getEnv().language;

  useEffect(() => {
    if (!headerData) {
      setIsLoading(true);
      dispatch(fetchGetNavigation()).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, token, isAuth, headerData, selectedLang]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   dispatch(fetchGetNavigation()).then(() => {
  //     setIsLoading(false);
  //   });
  // }, []);

  return (
    <Container>
      <FontStyles />
      <GlobalStyles />
      {isLoading && <Loading />}
      {headerData && <Header appHeaderAPI={headerData} />}
      <StretchedMain>{children}</StretchedMain>
      {footerData && <Footer footerPanelData={footerData} />}
    </Container>
  );
};

DefaultLayout.propTypes = {};

export default DefaultLayout;
