import { makeStyles } from "@material-ui/core";
import React,{ Fragment } from "react";

const NoRecord = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.alert}>
        <strong style={{ color: props.color ? props.color : "black" }}>
          {props.message ? props.message : "No Records Found!"}
        </strong>
      </div>
    </Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  alert: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default React.memo(NoRecord);
