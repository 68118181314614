import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function AlertDialog(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={() => props.open}
        onClose={() => props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {props.headerMessage &&
        <DialogTitle id="alert-dialog-title" className={props.backgroundColor ? classes.popupHeadColor : ""}>
          {props.headerMessage ? props.headerMessage : ""}
          {props.backgroundColor &&
            <span className={classes.crossIcon} onClick={props.handleClose}>&times;</span>
          }
        </DialogTitle>
      }
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.contentMessage ? props.contentMessage : ""}
          </DialogContentText>
        </DialogContent>
        {props.markCheckbox &&
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => props.onHandleChange(e.target.checked)}
                    name="idt_terms_checkbox"
                    color="primary"
                  />
                }
                label={props.checkboxMessage ? props.checkboxMessage : ""}
              />
            </DialogContentText>
          </DialogContent>
        }
      <DialogActions>
        {props.bncBtnLabel && 
          <Button onClick={() => props.handleContinueClick(true)} color="primary">
            {props.bncBtnLabel ? props.bncBtnLabel : ""}
          </Button>
        }
        {props.non_bncBtnLabel && 
          <Button onClick={() => props.handleContinueClick(false)}>
            {props.non_bncBtnLabel ? props.non_bncBtnLabel : ""}
          </Button>
        }

        {props.yesBtnLabel && 
          <Button 
            onClick={props.handleAgreeClick}
            color="primary"
            autoFocus
            disabled={props.markCheckbox && !props.termsCheckboxStatus ? true : false}
          >
            {props.yesBtnLabel ? props.yesBtnLabel : ""}
          </Button>
        }
        {props.noBtnLabel &&
          <Button onClick={props.handleClose} color="primary">
            {props.noBtnLabel ? props.noBtnLabel : ""}
          </Button>
        }
      </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  popupHeadColor: {
    backgroundColor: "#0076B3",
    padding: "0.75rem 1rem",
    color: "#fff",
    textTransform: "uppercase",
  },
  crossIcon: {
    fontSize: '1.5em',
    cursor: "pointer",
    position: "absolute",
    right: '15px',
    top: '5px',
  },
}));
AlertDialog.propTypes = {};
