import { TableBody, TableRow } from "@material-ui/core";
import { useContext } from "react";
import { LocalContext } from "../../LocalProvider";
import { StyledTableCell, useStyles } from "./Promotion.styles";
import PromotionDetails from "./PromotionDetails";
import { PromotionContext } from "./PromotionContext";
import NoRecord from "../common/NoRecord.component";

const PromotionList = () => {
  const { promotionDataProvider, getPromotionsListHeader } = useContext(PromotionContext);
  const { translate } = useContext(LocalContext);
  const classes = useStyles();
  return promotionDataProvider.length ? (
    <TableBody>
      {promotionDataProvider.length !== 0 &&
        promotionDataProvider.map((row, key) => {
          return <PromotionDetails promotionDataProvider={row} key={key} />;
        })}
    </TableBody>
  ) : (
    <TableBody>
      <TableRow>
        <StyledTableCell className={classes.empty} colSpan={getPromotionsListHeader.length}>
          <NoRecord message={translate("No Records Found!")} />
        </StyledTableCell>
      </TableRow>
    </TableBody>
  );
};

export default PromotionList;
