import { getSelectedAssociatedCustomer } from "../../components/common/helper/helper";
import {
  apiHeaderWithSiteId,
  creditAmountAPIUrl,
  statementOfAccountAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const creditAmountActionTypes = {
  FETCH_CREDIT_AMOUNT_DATA_SUCCESS: "FETCH_CREDIT_AMOUNT_DATA_SUCCESS",
  FETCH_CREDIT_DATA_FAILTURE: "FETCH_CREDIT_AMOUNT_DATA_FAILURE",
};

const fetchCreditAmountDataSuccessAction = (data) => {
  return {
    type: creditAmountActionTypes.FETCH_CREDIT_AMOUNT_DATA_SUCCESS,
    data,
  };
};

// FOR DISPLAYING RECORDS IN WEB (WITH PAGINATION)
export const fetchCreditAmountAction =
  (isAuth, token, payload) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;

    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    await fetch(creditAmountAPIUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchCreditAmountDataSuccessAction(data));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

// FOR DISPLAYING RECORDS IN EXCEL (WITHOUT PAGINATION, ALL RECORDS)
export const fetchCreditAmountExcelAction =
  (isAuth, token, payload) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    return await fetch(creditAmountAPIUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    }).then((response) => {
      return response.json().then((data) => {
        return data;
      });
    });
    // .then((response) => response.json())
    // .then((data) => {
    //   return data;
    //   dispatch(loadingEnd());
    // })
  };

// FETCH STATEMENT OF ACCOUNT ACTION ONLY FOR LISTING(NO NEED TO STORE IN REDUX)
export const fetchStatementOfAccountAction =
  (isAuth, token) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    return await fetch(statementOfAccountAPIUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => console.log(err));
  };

// FETCH STATEMENT OF ACCOUNT DETAILS ACTION (NO NEED TO STORE IN REDUX)
export const fetchStatementOfAccountDetailsAction =
  (isAuth, token, urlLink) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;

    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    return await fetch(urlLink, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => console.log(err));
  };
