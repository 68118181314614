import { TableBody, TableRow } from "@material-ui/core";
import { useContext } from "react";
import { LocalContext } from "../../LocalProvider";
import { StyledTableCell, useStyles } from "./Wishlist.styles";
import { WishlistContext } from "./WishlistContext";
import WishlistDetails from "./WishlistDetails";

const WishlistList = () => {
  const { wishlistDataProvider, clearWishList } = useContext(WishlistContext);
  const { translate } = useContext(LocalContext);
  const classes = useStyles();
  return wishlistDataProvider.length ? (
    <TableBody>
      {wishlistDataProvider.length !== 0 &&
        wishlistDataProvider.map((row, key) => {
          return <WishlistDetails wishlistDataProvider={row} key={key} />;
        })}
    </TableBody>
  ) : (
    <TableBody>
      <TableRow>
        <StyledTableCell className={classes.empty} colSpan="5">
          {clearWishList && <p> {translate("Your wishlist is empty")}</p>}
        </StyledTableCell>
      </TableRow>
    </TableBody>
  );
};

export default WishlistList;
