import styled from "styled-components";
import { colors } from "../../styles/variables";

const Anchor = styled.a`
  color: ${(props) => (props.color ? props.color : "inherit")};
  text-decoration: none;
  &:focus {
    outline-color: ${colors.black};
  }
`;

export default Anchor;
