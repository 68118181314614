export const cartItemsDataSelector = (state) =>
  state.cartItems.items !== null && state.cartItems.items?.length > 0
    ? state.cartItems.items
    : [];

export const cartItemsTotalCountSelector = (state) =>
  state.cartItems.productsCount &&
  state.cartItems.productsCount !== null &&
  state.cartItems.productsCount.defaultCount
    ? state.cartItems.productsCount.defaultCount
    : 0;

export const cartItemsEstimatedTax = (state) =>
  state.cartItems.getCartAPI?.estimatedtax
    ? state.cartItems.getCartAPI.estimatedtax
    : null;

export const cartItemsFreightRecovery = (state) =>
  state.cartItems.getCartAPI?.freightrecovery
    ? state.cartItems.getCartAPI.freightrecovery
    : 0;

export const getCartOrderTypeSelector = (state) =>
  state.cartItems.getCartAPI?.orderType
    ? state.cartItems.getCartAPI.orderType
    : null;

export const cartItemsTotalValueSelector = (state) =>
  state.cartItems.getCartAPI?.grandTotal
    ? state.cartItems.getCartAPI.grandTotal
    : null;

export const orderDataSelector = (state) =>
  state.cartItems.orderDetails ? state.cartItems.orderDetails : null;

export const cartItemsSubTotalValueSelector = (state) =>
  state.cartItems.getCartAPI?.subtotal
    ? state.cartItems.getCartAPI.subtotal
    : null;

export const getCartBNCFlagSelector = (state) =>
  state.cartItems.getCartAPI?.isBNCFlag
    ? state.cartItems.getCartAPI.isBNCFlag
    : false;

export const cartItemsTotalQtyCountSelector = (state) =>
  state.cartItems.productsCount &&
  state.cartItems.productsCount !== null &&
  state.cartItems.productsCount.totalQuantityCount
    ? state.cartItems.productsCount.totalQuantityCount
    : 0;

export const getSuggestedOrderFlagSelector = (state) =>
  state.cartItems.getCartAPI?.isSuggestedOrder
    ? state.cartItems.getCartAPI.isSuggestedOrder
    : false;

export const getCartBNCMultiFlagSelector = (state) =>
  state.cartItems.getCartAPI?.isBNCMultiFlag
    ? state.cartItems.getCartAPI.isBNCMultiFlag
    : false;