import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  OutlinedInput
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth from "@material-ui/core/withWidth";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { LocalContext } from "../LocalProvider";
import checkYourEmail from "../mocks/checkYourEmail.json";
import { forgotPassword } from "../store/actions/navItems.actions";
import { colors } from "./../styles/variables";
import CheckEmail from "./CheckEmail.component";
import { getEnv } from "../utils/urls";

const SubmitButton = styled.button`
  background-color: ${colors.lightBlue};
  margin: auto;
  width: 100%;
  color: ${colors.white};
  padding: 0.5em 4.8em;
  border: none;
  font-size: 1.3em;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryHover};
    color: ${colors.lightGrey};
  }
  &:disabled {
    background-color: ${colors.white};
    color: ${colors.grey};
    border: 1px solid ${colors.grey};
    pointer-events: none;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
      },
    },
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  dialogStyle: {},
  containerStyle: {
    padding: "1.75rem 2.375rem 1.375rem 2.25rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem",
    },
  },
  paper: {
    width: "46.125em",
    overflow: "hidden",
    background: "#f7f7f7",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      margin: 0,
      height: "100%",
      maxHeight: "100%",
    },
  },
  signInTitle: {
    display: "block",
    textAlign: "center",
    fontSize: "2.5rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    zIndex: 1000,
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#lightgray",
    [theme.breakpoints.down("sm")]: {},
  },
  inputBox: {
    width: "100%",
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  passwordNotification: {
    fontSize: "0.8rem",
    color: "red",
    margin: "0.25rem 0 1rem 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem",
    },
    "& p": {
      fontSize: "0.8rem",
      margin: "0",
    },
  },
  gridMargin: {
    margin: "1rem 0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& > .MuiInputLabel-root": {
      [theme.breakpoints.down("sm")]: {
        zIndex: "0",
      },
    },
  },
  BtnBlueBG: {
    borderRadius: "0",
    height: "100%",
    border: "none",
    backgroundColor: "#FFFFFF80",
    "&:active": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.active": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
  },
  forgotModal: {
    padding: "15px"
  },  
  loaderOuter: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: "9999",
  },
  loaderInner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }
}));
const ForgetPassword = (props) => {
  const classes = useStyles();
  const { translate } = useContext(LocalContext);
  const [openForget, setOpenForget] = useState(true);
  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const [isForgotPasswordSubmit, setIsForgotPasswordSubmit] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [customerNo, setCustomerNo] = useState("");

  const onCheckEmail = (event) => {
    event.preventDefault();
    // API Call
    setLoading(true);
    const payload = {
      email: email,
    };
    if(getEnv().siteId === "1") {
      payload.customerNumber = customerNo;
    }
    dispatch(forgotPassword(payload))
      .then((res) => {
        setLoading(false);
        if (res && res.success === false) {
          setIsForgotPasswordSubmit(false);
          setErrorMsg(res.msg);
        } else {
          setIsForgotPasswordSubmit(true);
          handleCloseSignIn();
          // setOpen(false);
          setErrorMsg("");
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };

  const handleCloseSignIn = () => {
    setOpenForget(false);
  };

  /* Event on Forgot Password Submit => Enter registered Email */
  const handelChangeEmail = (email_add) => {
    setErrorMsg("");
    // setEmail(email);
    let emailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const checkEmail = emailRegEx.test(email_add);
    let errorMsg = "";
    if(checkEmail) {
      setEmail(email_add);
    } else {
      setEmail("");
      errorMsg = translate("forgot_email_error");
    }
    // let errorMsg = !checkEmail ? "Email is not valid" : "";
    setErrorMsg(errorMsg);
    // setIsEmailValid(checkEmail);
    if(customerNo !== "" && checkEmail) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    // setIsDisable(!checkEmail);
  };

  const handelChangeCustNo = (custNo) => {
    setErrorMsg("");
    // setCustomerNo(custNo);
    let numberRegEx = /^\d+$/;
    const checkNo = numberRegEx.test(custNo);
    let errorMsg = "";
    if(checkNo) {
      setCustomerNo(custNo);
    } else {
      setCustomerNo("");
      errorMsg = translate("forgot_custNo_error");
    }
    // let errorMsg = !checkNo ? "Customer No. is not valid" : "";
    setErrorMsg(errorMsg);
    // setIsEmailValid(checkNo);
    if(email !== "" && checkNo) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    // setIsDisable(!checkNo);
  }

  return (
    <div>
      {isForgotPasswordSubmit ? (
        <CheckEmail
          handleCloseSignIn={props.handleCloseSignIn}
          pageData={checkYourEmail}
        />
      ) : (
        <Dialog
          open={openForget}
          onClose={props.handleCloseSignIn}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.paper }}
          fullWidth={true}
        >
          <form className={classes.root} noValidate>
            <div className={classes.containerStyle}>
              <Avatar className={classes.closeButton}>
                <IconButton
                  aria-label="Close"
                  onClick={props.handleCloseSignIn}
                >
                  <CloseIcon />
                </IconButton>
              </Avatar>
              <DialogTitle id="form-dialog-title">
                <strong className={classes.signInTitle}>
                  {translate("forgot.password")}
                </strong>
              </DialogTitle>
              <DialogContent className={classes.forgotModal}>
                {loading && (
                  <div className={classes.loaderOuter}>
                    <div className={classes.loaderInner}>
                      <CircularProgress />
                    </div>
                  </div>
                )}
                <DialogContentText
                  style={{
                    fontSize: "17px",
                    color: "#000000",
                    padding: "15px"
                  }}
                >
                  {translate("forgot.password.subtext")}
                </DialogContentText>
                {errorMsg && (
                  <p className={classes.passwordNotification}>{errorMsg}</p>
                )}

                {getEnv().siteId === "1" && (
                  <FormControl variant="outlined" className={classes.gridMargin} style={{ width: "100%" }}>
                    <InputLabel
                      htmlFor="component-outlined"
                      style={{ fontWeight: "bold" }}
                    >
                      {translate("Customer Number")}
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      label={translate("Customer Number")}
                      onChange={(e) => handelChangeCustNo(e.target.value)}
                      className={classes.inputBox}
                      type="text"
                      autoFocus={getEnv().siteId === "1" ? true : false}
                    />
                  </FormControl>
                )}
                  
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel
                      htmlFor="component-outlined"
                      style={{ fontWeight: "bold" }}
                    >
                      {translate("Email")}
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      label={translate("Email")}
                      onChange={(e) => handelChangeEmail(e.target.value)}
                      className={classes.inputBox}
                      type="text"
                      autoFocus={getEnv().siteId !== "1" ? true : false}
                    />
                  </FormControl>
              </DialogContent>
              <DialogActions style={{ padding: "18.5px 14px" }}>
                <SubmitButton
                  onClick={onCheckEmail}
                  color="primary"
                  className={`${classes.gridMargin} ${classes.BtnBlueBG}`}
                  disabled={isDisable}
                >
                  <strong>{translate("Submit")}</strong>
                </SubmitButton>
              </DialogActions>
            </div>
          </form>
        </Dialog>
      )}
    </div>
  );
};

ForgetPassword.propTypes = {};

export default withWidth()(ForgetPassword);
