import { brandSubBrandActionTypes } from "../actions/brandSubBrand.actions";

export default function brandSubBrandReducer(state = null, action) {
  switch (action.type) {
    case brandSubBrandActionTypes.FETCH_BRANDSUBBRAND_DATA_SUCCESS:
      return { ...state, brand: action.data };
    case brandSubBrandActionTypes.FETCH_SUBBRAND_DATA_SUCCESS:
      return { ...state, subBrand: action.data };
    default:
      return state;
  }
}
