import { getSelectedAssociatedCustomer } from "../../components/common/helper/helper";
import {
  addReturnOrderImagesAPIUrl,
  apiHeaderWithSiteId,
  cancelOrderAPIUrl,
  createReturnOrderAPIUrl,
  deleteOrderAPIUrl,
  downloadPDFReturnOrderAPIUrl,
  getOrderDetailsAPIUrl,
  getOrderHistoryAPIUrl,
  orderInfoAPIUrl,
  returnMaterialReasonCodeAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const orderHistoryActionTypes = {
  FETCH_ORDER_HISTORY_DATA_SUCCESS: "FETCH_ORDER_HISTORY_DATA_SUCCESS",
  FETCH_ORDER_HISTORY_DATA_FAILTURE: "FETCH_ORDER_HISTORY_DATA_FAILURE",
  FETCH_SELECTED_ORDER_DATA_SUCCESS: "FETCH_SELECTED_ORDER_DATA_SUCCESS",
  FETCH_SELECTED_ORDER_DATA_FAILTURE: "FETCH_SELECTED_ORDER_DATA_FAILTURE",
  CANCEL_ORDER_DATA_SUCCESS: "CANCEL_ORDER_DATA_SUCCESS",
  CANCEL_ORDER_DATA_FAILURE: "CANCEL_ORDER_DATA_FAILURE",
  REMOVE_PRODUCT_DATA_SUCCESS: "REMOVE_PRODUCT_DATA_SUCCESS",
  UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS: "UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS",
  UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS:
    "UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS",
  UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS:
    "UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS",
};

export const orderHistoryClassTypes = {
  ORDER_CLASS_TYPE_CONFIRMED: "Confirmed",
  ORDER_CLASS_TYPE_COMPLETED: "Completed",
  ORDER_CLASS_TYPE_CANCELLED: "Order Cancelled",
  ORDER_CLASS_TYPE_BEINGPROCESSED: "Order is being processed",
  ORDER_CLASS_TYPE_ACKNOWLEDGED: "Order Acknowledged",
  ORDER_CLASS_TYPE_HASPROCESSED: "Order has been proceeded",
  ORDER_CLASS_TYPE_ISPROCESSING: "Order is processing",
  ORDER_CLASS_TYPE_CLAIMPROCESSING: "Claim processing",
  ORDER_CLASS_TYPE_CLAIMPENDING: "Claim pending",
  ORDER_CLASS_TYPE_CLAIMREJECTED: "Claim rejected",
  ORDER_CLASS_TYPE_CLAIMAPPROVED: "Claim approved",
};

const fetchOrderHistoryDataSuccessAction = (data) => {
  return {
    type: orderHistoryActionTypes.FETCH_ORDER_HISTORY_DATA_SUCCESS,
    data,
  };
};

const fetchSelectedOrderDataSuccessAction = (data) => {
  return {
    type: orderHistoryActionTypes.FETCH_SELECTED_ORDER_DATA_SUCCESS,
    data,
  };
};

const cancelOrderDataSuccessAction = (orderId) => {
  return {
    type: orderHistoryActionTypes.CANCEL_ORDER_DATA_SUCCESS,
    orderId,
  };
};

const fetchRemoveProductDataSuccessAction = (productId) => {
  return {
    type: orderHistoryActionTypes.REMOVE_PRODUCT_DATA_SUCCESS,
    productId,
  };
};

const fetchUpdateQunatityDataSuccessAction = (productInfo, productQuantity) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS,
    productInfo,
    productQuantity,
  };
};

const fetchUpdateReturnReasonSuccessAction = (
  productInfo,
  productReasonCode
) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS,
    productInfo,
    productReasonCode,
  };
};

const fetchUpdateImageUploadSuccessAction = (productId, payload) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS,
    productId,
    payload,
  };
};
export const fetchOrderHistoryAction = (isAuth, token, payload) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(getOrderHistoryAPIUrl, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(fetchOrderHistoryDataSuccessAction(data));
      return data;
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

export const fetchSelectedOrderHistoryAction = (
  isAuth,
  token,
  orderId
) => async (dispatch) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(getOrderDetailsAPIUrl + "/" + orderId, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(fetchSelectedOrderDataSuccessAction(data));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

export const cancelOrderDataAction = (isAuth, token, orderId) => async (
  dispatch,
  getState
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(cancelOrderAPIUrl + "/" + orderId, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadingEnd());
      dispatch(cancelOrderDataSuccessAction(orderId));
      return data;
    })
    .catch((err) => console.log(err));
};

export const deleteOrderDataAction = (isAuth, token, orderId) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(deleteOrderAPIUrl + "/" + orderId, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(cancelOrderDataSuccessAction(orderId));
      return data;
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

export const orderInfoDataAction = (isAuth, token, orderId) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(orderInfoAPIUrl + "?orderId=" + orderId, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => console.log(err));
};

export const returnMaterialReasonCodeDataAction = (token) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(returnMaterialReasonCodeAPIUrl, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => console.log(err));
};

export const deleteReturnItemsDataAction = (productId) => (dispatch) => {
  dispatch(loadingStart());
  dispatch(fetchRemoveProductDataSuccessAction(productId));
  dispatch(loadingEnd());
};

export const updateReturnQunatityDataAction = (
  productInfo,
  productQuantity
) => (dispatch) => {
  if (productQuantity >= 1) {
    dispatch(loadingStart());
    dispatch(
      fetchUpdateQunatityDataSuccessAction(productInfo, productQuantity)
    );
    dispatch(loadingEnd());
  }
};

export const updateReturnReasonCodeDataAction = (
  productInfo,
  productReasonCode
) => (dispatch) => {
  dispatch(loadingStart());
  dispatch(
    fetchUpdateReturnReasonSuccessAction(productInfo, productReasonCode)
  );
  dispatch(loadingEnd());
};

// RMA: Image Upload action
export const updateImageUploadCodeDataAction = (
  productId,
  payload,
  isAuth,
  token
) => async (dispatch) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  await fetch(addReturnOrderImagesAPIUrl, {
    method: "POST",
    headers: headers,
    body: payload,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("data ==>", data);
      dispatch(fetchUpdateImageUploadSuccessAction(productId, payload));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

export const createReturnOrderDataAction = (token, payload) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(createReturnOrderAPIUrl, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => console.log(err));
};

// FETCH INVOICE PDF FOR RETURN (NO NEED TO STORE IN REDUX)
export const fetchDownloadPDFAction = (isAuth, token, urlLink) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  return await fetch(downloadPDFReturnOrderAPIUrl + "/" + urlLink, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => console.log(err));
};
