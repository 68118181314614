import { creditAmountActionTypes } from "../actions/creditAmount.actions";

export default function creditAmountReducer(state = null, action) {
  switch (action.type) {
    case creditAmountActionTypes.FETCH_CREDIT_AMOUNT_DATA_SUCCESS:
      return {
        items: action.data.items,
        totalCount: action.data.totalCount,
      };

    default:
      return state;
  }
}
