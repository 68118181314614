import styled from "styled-components";
import { bgSetting, mediaQueries } from "../../styles/variables";

const BackgroundContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: url(/${bgSetting.dottedPatternDesktop}) repeat center;
  @media ${mediaQueries.belowiPad} {
    background: url(/${bgSetting.dottedPatternMobile}) repeat center;
  }
`;

export default BackgroundContainer;
