import { apiHeaderWithSiteId, getMyAccountUrl } from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const myAccountActionTypes = {
  FETCH_MY_ACCOUNT_DATA_SUCCESS: "FETCH_MY_ACCOUNT_DATA_SUCCESS",
  FETCH_MY_ACCOUNT_DATA_FAILTURE: "FETCH_MY_ACCOUNT_DATA_FAILURE",
};

const fetchMyAccountSuccessAction = (data) => {
  return {
    type: myAccountActionTypes.FETCH_MY_ACCOUNT_DATA_SUCCESS,
    data,
  };
};

// TO GET CURRENT LOGGED-IN USER BILL,SHIP & SOLD TO ADDRESS
export const fetchMyAccountDetailsAction = (isAuth, token) => async (
  dispatch
) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (apiHeaderWithSiteId["AssociatedCustomer"]) {
    delete apiHeaderWithSiteId["AssociatedCustomer"];
  }
  return await fetch(getMyAccountUrl, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(fetchMyAccountSuccessAction(data));
      dispatch(loadingEnd());
    })
    .catch((err) => console.log(err));
};
