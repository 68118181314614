import Login from "../components/login/Login.component";

function LoginPage(props) {
  return (
    <div>
      <Login handleCloseSignIn={props.handleCloseSignIn} />
    </div>
  );
}

LoginPage.propTypes = {};

export default LoginPage;
