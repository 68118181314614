import * as React from "react";
import { useHistory, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { selectedLanguage } from "../../store/selectors/language.selector";
import { getHeaderToken } from "../../store/selectors/navItems.selector";
import { getStoreListByPromotion } from "../../store/actions/promotion.action";
import { LocalContext } from "../../LocalProvider";
import { Button, Grid, Paper, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PromotionDetailTableView from "./PromotionDetailTableView";
import { PromotionContext } from "./PromotionContext";
import { colors, mediaQueries } from "../../styles/variables";
import styled from "styled-components";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';
import AlertDialog from "../common/AlertDialog";
import { Pagination, ToggleButton } from "@material-ui/lab";
import { totalPage, getPaginatedData, pagePerItems } from "../../utils/pagination";
import { getFormatedDate } from "../common/helper/helper";
import { getStoreByPromotionHeader } from "../common/TableHeaders";
import { useStyles } from "./Promotion.styles";

const AttchmentLink = styled(NavLink)`
  color: ${colors.blue};
  text-decoration: none;
  text-align: left;
  position: relative;
  text-transform: capitalize;
  display: inline-block;
  font-size: 1.25rem;
  @media ${mediaQueries.belowContainer} {
    font-size: 1rem;
  }
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 0.85rem;
  }
  &:hover {
    color: ${colors.greyDark};
  }
  &.active {
    border-bottom: 2px solid red;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    color: ${colors.blue};
    font-size: 1.25rem;
  }
`;


const PromotionList = () => {
  const { promotionDetailData,
    handelSetAttachmentAction,
    handleDealerResponse,
    dealerResponse,
    attachmentStatus,
    errorMsg,
    successMsg,
    setLoading,
    setErrorMsg
  } = useContext(PromotionContext);
  const token = useSelector(getHeaderToken);
  const dispatch = useDispatch();
  const singlePromotionData = promotionDetailData?.[0]?.promotion || null;
  const singlePromotionDealerResponse = promotionDetailData?.[0]?.dealerResponse || "";
  const [storePage, setStorePage] = useState(1);
  const { translate } = useContext(LocalContext);
  const [actionStatus, setActionStatus] = useState(false);
  const [dealerResponseMsg, setDealerResponseMsg] = useState("");
  const [dealerResponseStatus, setDealerResponseStatus] = useState();
  const [open, setOpen] = useState(false);
  const [termsCheckboxStatus, setTermsCheckboxStatus] = useState(false);
  const [promotionStoreData, setPromotionStoreData] = useState([]);
  const [promotionStoreDataProvider, setPromotionStoreDataProvider] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const { promotionId } = useParams();
  const selectedLocLanguage = useSelector(selectedLanguage);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [storeName, setStoreName] = useState("");
  const [payload, setPayload] = useState({
    promotionId: promotionId,
    offset: (storePage - 1) * pagePerItems,
    limit: pagePerItems,
    storeName: ""
  });
  
  let history = useHistory();
  const backToPromotion = () => {
    history.push("/projectList");
  };

  useEffect(() => {
    if(singlePromotionDealerResponse === "No Response") {
      setDealerResponseMsg("");
      setDealerResponseStatus(true);
    } else {
      setDealerResponseMsg(translate(singlePromotionDealerResponse));
      setDealerResponseStatus(false);
    }    
  }, [singlePromotionDealerResponse]);
  
  // For Initial Attachments Status To Show Action Buttons
  useEffect(() => {
    if(singlePromotionData?.attachmentCount > 0 &&
        singlePromotionData.attachments.length > 0 &&
        (attachmentStatus?.length == 0 || !attachmentStatus)
      ) {
      const getAttachmentStatus = singlePromotionData.attachments.filter((singleAttach) => singleAttach.readCount == 0);
      if(getAttachmentStatus?.length == 0) {
        setActionStatus(true);
      } else {
        setActionStatus(false);
      }
    }
  }, [singlePromotionData, actionStatus, attachmentStatus]);

  // After Clikcking On Any Attachment & To Check Other Attachments Read Count To Show Action Buttons
  useEffect(() => {
    if(!dealerResponseStatus) {
      setActionStatus(false);
    } else if(attachmentStatus?.length > 0) {
      const getAttachmentStatus = attachmentStatus.filter((singleAttach) => singleAttach.readCount == 0);
      if(getAttachmentStatus?.length == 0) {
        setActionStatus(true);
      } else {
        setActionStatus(false);
      }
    }
  }, [attachmentStatus, actionStatus, dealerResponseStatus]);

  useEffect(() => {
    if(token && promotionId) {
      getPromotionStoreData();
    }
  }, [promotionId, payload]);

  // Get Promotion DATA Handler
  const getPromotionStoreData = () => {
    setLoading(true);
    dispatch((getStoreListByPromotion(token, payload))).then((res) => {
      setLoading(false);
      if (res && res.success === false) {
        setErrorMsg(res.msg);
        setPromotionStoreData([]);
        setPromotionStoreDataProvider([]);
        setTotalPageCount(0);
      } else {
        setPromotionStoreData(res.data);
        const paginatedStoreData = getPaginatedData(res.data?.[0]?.stores, pagePerItems, 1);
        setPromotionStoreDataProvider(paginatedStoreData);
        setTotalPageCount(totalPage(res.data?.[0]?.totalStoreCnt, pagePerItems));
        setErrorMsg("");
      }
    })
    .catch((err) => {
      setErrorMsg(err);
    });
  };
  
  const handleAttachment = (fileId, dealerId) => {
    handelSetAttachmentAction(fileId, dealerId);
    return null;
  }

  const onHandleChange = (checkboxValue) => {
    setTermsCheckboxStatus(checkboxValue);
  }

  const handleAgreeClick = (isAgree) => {
    if (isAgree && termsCheckboxStatus) {
      handleDealerResponse(null, promotionDetailData?.[0]?.dealerId, "Accepted")
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStorePagination = (event, value) => {
    if (value !== storePage) {
      setStorePage(value);
      setPayload({ 
        ...payload,
        offset: (value - 1) * pagePerItems      
      });
    }
  };

  const handleChangeStoreName = (event) => {
    setStoreName(event.target.value)
  }
  
  const handleChangeSearch = () => {
    setPayload({ 
      ...payload,
      offset: (storePage - 1) * pagePerItems,
      storeName: storeName,
    });
  }

  const handleChangeReset = () => {
    setStoreName("");
    setPayload({ 
      ...payload,
      offset: (storePage - 1) * pagePerItems,
      storeName: "",
    });
  }

  const classes = useStyles();
  return singlePromotionData ? (
    <>
      {open &&
        <AlertDialog
            open={open}
            handleClose={handleClose}
            handleAgreeClick={() => handleAgreeClick(true)}
            onHandleChange={(value) => onHandleChange(value)}
            headerMessage={translate("IDT_terms_popup_header_message" || errorMsg)}
            contentMessage={translate("IDT_terms_header_message")}
            markCheckbox={true}
            checkboxMessage={translate("IDT_terms_checkbox_message")}
            termsCheckboxStatus={termsCheckboxStatus}
            yesBtnLabel={translate("Continue")}
            noBtnLabel={translate("Cancel")}
        />
      }

    <div className={classes.titleRow}>
        <h2 className={classes.titleSummary}>
          {translate("Project Details")}
        </h2>
        <div className={classes.buttonStyle}>
          {dealerResponseMsg &&
            <Chip 
              label={dealerResponseMsg}
              color={dealerResponseMsg === "Accepted" ? "primary" : "secondary"}
              style={
                {
                  fontWeight: "bold",
                  background: dealerResponseMsg === "Accepted" ? "#5cb85c" : "#d9534f"
                }
              }
            />
          }
          {actionStatus && (
            <>
              <Button
                className={classes.whiteBtn +" "+ classes.acceptBtn}
                onClick={() =>
                  setOpen(true)
                }
              >
                {translate("Accept")}
                <CheckCircleIcon fontSize="small" />
              </Button>
              <Button
                className={classes.whiteBtn +" "+ classes.rejectBtn}
                onClick={() =>
                  handleDealerResponse(null, promotionDetailData?.[0]?.dealerId, "Rejected")
                }
              >
                {translate("Reject")}
                <CancelIcon fontSize="small" />
              </Button>
            </>
          )}
          <Button
            className={classes.whiteBtn}
            onClick={() => backToPromotion()}
          >
            {translate("Back to Project")}
          </Button>
        </div>
      </div>

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <p className={classes.orderDetailTitle}>
                      {translate("Project Details")}
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Project Code")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.promotionCode}
                      </span>
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Project Name")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.projectName}
                      </span>
                    </p>
                      <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Start Date")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.startTime &&                            
                          getFormatedDate(
                            singlePromotionData.startTime,
                            "MMMM Do, YYYY"
                          )}
                      </span>
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("End Date")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.endTime &&                            
                          getFormatedDate(
                            singlePromotionData.endTime,
                            "MMMM Do, YYYY"
                          )}
                      </span>
                    </p>
                    {/* <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Execution Plan")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.executionPlan}
                      </span>
                    </p> */}
                </Grid>

                <Grid item xs={12} sm={4}>
                    <p className={classes.orderDetailTitle}>
                      {translate("Project Information")}
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Top Code")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.topCode}
                      </span>
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Product Category")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.productCategory}
                      </span>
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Investment Category")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.investmentCategory}
                      </span>
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Sales Classification")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.salesClassification}
                      </span>
                    </p>
                    <p className={classes.orderDetailRow}>
                      <span className={classes.orderDetailLabel}>
                        {translate("Sales Item Type")}
                      </span>
                      <span className={classes.orderDetailLabelValue}>
                        {singlePromotionData.salesItemType}
                      </span>
                    </p>
                </Grid>

                {singlePromotionData.attachmentCount > 0 && singlePromotionData.attachments.length > 0 && 
                  <Grid item xs={12} sm={4}>
                    <p className={classes.orderDetailTitle}>
                      {translate("Attachments")}
                    </p>
                    {singlePromotionData.attachments.map((singleAttach) => {
                        return <p className={classes.orderDetailRow}>
                          <span className={classes.orderDetailLabel}>
                            <AttchmentLink target="_blank" to={{ pathname: singleAttach.fileURL }} onClick={() => handleAttachment(singleAttach.fileId, promotionDetailData?.[0]?.dealerId)} exact>
                              {singleAttach.filename}
                            </AttchmentLink>
                          </span>
                        </p>
                    })}
                  </Grid>                
                }
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          className={`${classes.headerGridContainer}`}
        >
          <Grid item md={3} xs={12}>
            <Paper
              className={`${classes.paperHeaderGridLeft} ${classes.paperHeaderGrid}`}
            >
              <h2 className={classes.titleSummary}>
                {translate("Store List")}
              </h2>
              <ToggleButton
                value="Toggle"
                aria-label="Toggle"
                className={`${classes.BtnTransparentBG} ${classes.onlyMobile} ${classes.toggleMobile}`}
                onClick={toggle}
              >
                Filter
                <ExpandLessIcon />
              </ToggleButton>
            </Paper>
          </Grid>

          <Grid
            item
            md={9}
            xs={12}
            className={
              isOpen
                ? `${classes.headerGridRight} ${classes.paperHeaderGrid} ${classes.headerGridRightMobile} ${classes.headerGridRightMobileActive}`
                : `${classes.headerGridRight} ${classes.paperHeaderGrid} ${classes.headerGridRightMobile}`
            }
          >
              <form className={classes.formContainer} noValidate>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="store-info"
                    placeholder={translate("Search Store")}
                    value={storeName}
                    className={classes.textField}
                    onChange={(e) => handleChangeStoreName(e)}
                  />
                </FormControl>
                <Button
                  className={classes.storeFilterBtn}
                  onClick={() => handleChangeSearch()}
                  variant="contained"
                >
                  {translate("Search")}
                </Button>
                <Button
                  className={classes.storeFilterBtn}
                  onClick={() => handleChangeReset()}
                  variant="contained"
                >
                  {translate("Reset")}
                </Button>
              </form>
          </Grid>
        </Grid>

        <PromotionDetailTableView
          storeData={promotionStoreDataProvider}
          dealerData={promotionStoreData}
          tableHeaders={getStoreByPromotionHeader}
          dealerResponse={dealerResponse}
        />

        <div className={classes.buttonStyle}>
          <Pagination
            className={classes.pagination}
            size="large"
            count={totalPageCount}
            page={storePage}
            onChange={(e, value) => handleChangeStorePagination(e, value)}
            color="primary"
            showFirstButton
            showLastButton
            boundaryCount={2}
          />
        </div>
      </div>
  </>
  ) : (
    <p align="center">{translate("No Records Found!")}</p>    
  );
};

export default PromotionList;