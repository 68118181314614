import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootActionTypes } from "../actions/root.actions";
import initialState from "../initial-state";
import brandSubBrandReducer from "./brandSubBrand.reducer";
import cartReducer from "./cart.reducer";
import cartItemsReducer from "./cartItems.reducer";
import checkOutReducer from "./checkOut.reducer";
import creditAmountReducer from "./creditAmount.reducer";
import homePageReducer from "./homePage.reducer";
import languageReducer from "./language.reducer";
import myAccountReducer from "./myAccount.reducer";
import navItemsReducer from "./navItems.reducer";
import orderHistoryReducer from "./orderHistory.reducer";
import productReducer from "./product.reducer";
import specialCartReducer from "./specialCart.reducer";
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "cart",
    "product",
    "cartItems",
    "navItems",
    // "orderHistory",
    // "checkOut",
    "language",
  ],
};

const rootReducer = (state = initialState.root, action) => {
  if (action.type === rootActionTypes.LOADING_START) {
    return {
      ...state,
      loading: state.loading + 1,
    };
  } else if (action.type === rootActionTypes.LOADING_END) {
    return {
      ...state,
      loading: state.loading - 1,
    };
  }

  if (action.type === rootActionTypes.USER_LOGGED_OUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    localStorage.clear();

    // return {
    //   state: undefined,
    // };
  }

  return state;
};

const rootReducerData = combineReducers({
  homePage: homePageReducer,
  navItems: navItemsReducer,
  cart: cartReducer,
  specialCart: specialCartReducer,
  product: productReducer,
  brandSubBrand: brandSubBrandReducer,
  orderHistory: orderHistoryReducer,
  cartItems: cartItemsReducer,
  checkOut: checkOutReducer,
  myAccount: myAccountReducer,
  creditAmount: creditAmountReducer,
  root: rootReducer,
  language: languageReducer,
});
export default persistReducer(persistConfig, rootReducerData);
