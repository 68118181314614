import { apiHeaderWithSiteId } from "../../utils/urls";
import { getEnv, getTranslations } from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const languageActionTypes = {
  FETCH_LANGUAGE_DATA_SUCCESS: "FETCH_LANGUAGE_DATA_SUCCESS",
  CHANGE_LANGUAGE_DATA_SUCCESS: "CHANGE_LANGUAGE_DATA_SUCCESS",
};

const fetchLanguageDataSuccessAction = (data) => {
  return {
    type: languageActionTypes.FETCH_LANGUAGE_DATA_SUCCESS,
    data,
  };
};

export const getLanguageAction = () => (dispatch) => {
  try {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    fetch(getTranslations, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const langData = {
          languageData: data,
          selectedLanguage: localStorage.getItem("language") ? localStorage.getItem("language") : getEnv().siteId == 1 ? "zh" : "en",
        };
        dispatch(fetchLanguageDataSuccessAction(langData));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  } catch(error) {
    console.log("🚀 ~ getLanguageAction ~ error:", error)    
  }
};

export const changeLanguageAction = (languageEnv) => (dispatch, getState) => {
  dispatch(loadingStart());
  const latestState = getState();
  const cartItemData = latestState.language.languageData;
  const langData = {
    languageData: cartItemData,
    selectedLanguage: languageEnv,
  };
  dispatch(fetchLanguageDataSuccessAction(langData));
  dispatch(loadingEnd());
};
