import { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";

let countdownInterval;
let timeout;

const SessionTimeout = ({ isAuthenticated }) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const idleTimer = useRef(null);
  // const dispatch = useDispatch();
  // const history = useHistory();

  // const token = useSelector(getHeaderToken);
  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      // dispatch(fetchLogout(token));
      // history.push("/");
      // localStorage.removeItem("selectedCustomerID");
      // localStorage.removeItem("selectedCustomerType");
      // localStorage.removeItem("selectedCustomerSapId");
      // localStorage.removeItem("persist:root");
    } catch (err) {
      console.error(err);
    }
  };

  // const handleContinue = () => {
  //   setTimeoutModalOpen(false);
  //   clearSessionInterval();
  //   clearSessionTimeout();
  // };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {    
    localStorage.removeItem("invalidAttempt");
    if (isAuthenticated) {
      handleLogout(true);
    }
  };

  return (
    <>
      {/* auto logout after 600 = 10 min */}
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={300000}
        // timeout={600 * 1000}
      />
    </>
  );
};

export default SessionTimeout;
