import { getSelectedAssociatedCustomer } from "../../components/common/helper/helper";
import {
  apiHeaderWithSiteId,
  productListAPIUrl,
  specialProductListAPIUrl,
  bncProductListAPIUrl
} from "../../utils/urls";
import { isAuthenticated } from "../selectors/navItems.selector";
import {
  productAddToWishListAPIUrl,
  productRemoveToWishListAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

/* 
1> Update store with user action data (Sync)
2> Update the backend with user action data (Async)
3> Get the latest cart data on page load. (Async)

*/
export const productActionTypes = {
  FETCH_PRODUCT_DATA_SUCCESS: "FETCH_PRODUCT_DATA_SUCCESS",
  FETCH_PRODUCT_DATA_FAILURE: "FETCH_PRODUCT_DATA_FAILURE",
  FETCH_WISHLIST_DATA_SUCESS: "FETCH_WISHLIST_DATA_SUCESS",
  FETCH_WISHLIST_DATA_FAILURE: "FETCH_WISHLIST_DATA_FAILURE",
  FETCH_UPDATE_PRODUCT_DATA_SUCCESS: "FETCH_UPDATE_PRODUCT_DATA_SUCCESS",
};

const fetchProductDataSuccessAction = (data) => {
  return {
    type: productActionTypes.FETCH_PRODUCT_DATA_SUCCESS,
    data,
  };
};

const getWishListItemsDataSuccessAction = (productId, value) => {
  return {
    type: productActionTypes.FETCH_WISHLIST_DATA_SUCESS,
    productId,
    value,
  };
};

const fetchUpdateProductDataSuccessAction = (productId, unitType) => {
  return {
    type: productActionTypes.FETCH_UPDATE_PRODUCT_DATA_SUCCESS,
    productId,
    unitType,
  };
};

export const fetchProductDataAction = (
  payload,
  isAuth,
  token,
  isSpecialProduct = false
) => async (dispatch, getState) => {
  const state = getState();
  const isAuthenticatedData = isAuthenticated(state);

  dispatch(loadingStart());
  var headers = apiHeaderWithSiteId;

  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  const apiURL = isSpecialProduct
    ? specialProductListAPIUrl
    : productListAPIUrl;
  return await fetch(apiURL, {
    headers: headers,
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((response) =>
      response.json().then((data) => {
        dispatch(fetchProductDataSuccessAction(data));
      })
    )
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

// Add to Wishlist from Product Listing Page
export const addToWishListDataAction = (productInfo, token) => async (
  dispatch,
  getState
) => {
  dispatch(loadingStart());
  var headers = apiHeaderWithSiteId;
  headers.Authorization = `Bearer ${token}`;

  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  await fetch(productAddToWishListAPIUrl + "/" + productInfo.id, {
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(getWishListItemsDataSuccessAction(productInfo.id, 1));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

// Remove to Wishlist from Product Listing Page
export const removeToWishListDataAction = (productInfo, token) => async (
  dispatch,
  getState
) => {
  dispatch(loadingStart());
  let associateCustmerID = localStorage.getItem("selectedCustomerID");
  var headers = apiHeaderWithSiteId;
  headers.Authorization = `Bearer ${token}`;

  if (associateCustmerID !== null) {
    headers.AssociatedCustomer = associateCustmerID;
  }
  await fetch(productRemoveToWishListAPIUrl + "/" + productInfo.id, {
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(getWishListItemsDataSuccessAction(productInfo.id, 0));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

// Change Unitype in Product Listing Page
export const updateUnitTypeDataAction = (productId, unitType) => (
  dispatch,
  getState
) => {
  dispatch(loadingStart());
  const latestState = getState();
  // TO UPDATE CART STORE
  latestState.cart.items.map((singleCartItem) => {
    return singleCartItem.id === productId
      ? singleCartItem.unitType.map((singleType) => {
          return singleType.key === unitType
            ? (singleType.active = 1)
            : singleType.key !== unitType
            ? (singleType.active = 0)
            : singleType.active;
        })
      : singleCartItem;
  });

  dispatch(fetchUpdateProductDataSuccessAction(productId, unitType));
  dispatch(loadingEnd());
};

// FOR DISPLAYING RECORDS IN EXCEL (WITHOUT PAGINATION, ALL RECORDS)
export const fetchProductExcelAction = (
  token,
  isSpecialProduct = false,
  isBNCProduct = false,
  isBNCMultiProduct = false
) => async (dispatch) => {
  dispatch(loadingStart());
  var headers = apiHeaderWithSiteId;
  headers.Authorization = `Bearer ${token}`;

  const payload = {
    isBNCFlag: isBNCProduct,
  }
  if(isBNCMultiProduct) {
    payload.isBNCMultiFlag = isBNCMultiProduct;
    payload.multipackType = "BNC_MULTIPACK";
  }

  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  const apiURL = JSON.parse(isSpecialProduct)
    ? specialProductListAPIUrl
    : productListAPIUrl;
  return await fetch(apiURL, {
    headers: headers,
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((response) => {
      // TODO: implicit return or single line return is required for promises
      return response.json().then((data) => {
        return data;
      });
    })
    .catch((err) => console.log(err));
};
