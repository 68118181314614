import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { useContext } from "react";
import { LocalContext } from "../../LocalProvider";
import { StyledTableCell, StyledTableRow, useStyles } from "./Promotion.styles";
import NoRecord from "../common/NoRecord.component";

const PromotionDetailTableView = (props) => {
  const classes = useStyles();
  const { translate } = useContext(LocalContext);

  return (
    <>
      {props.dealerData[0] && (
        <TableContainer
          component={Paper}
          className={classes.tableContainerStyle}
          key={"table1"}
        >
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <th colSpan={2} className={classes.productName}>
                  <p>{translate("Dealer ID")} :{" "}
                  {props.dealerData[0].dealerId
                    ? props.dealerData[0].dealerId
                    : "-"}</p>
                </th>
                <th colSpan={2} className={classes.productName}>
                  <p>{translate("Dealer Name")} :{" "}
                  {props.dealerData[0].dealerName
                    ? props.dealerData[0].dealerName
                    : "-"}</p>
                </th>
                <th colSpan={2} className={classes.productName}>
                  <p>{translate("Distributor Code")} :{" "}
                  {props.dealerData[0].distributorCode
                    ? props.dealerData[0].distributorCode
                    : "-"}</p>
                </th>
                <th colSpan={2} className={classes.productName}>
                  <p>{translate("Dealer Response")} :{" "}
                  {props?.dealerResponse !== "" ? props.dealerResponse : props.dealerData[0].dealerResponse
                    ? props.dealerData[0].dealerResponse
                    : "-"}</p>
                </th>
              </TableRow>
            </TableHead>
            <TableHead className={classes.detailTableHead}>
              <TableRow>
                {props.tableHeaders && props.tableHeaders.length > 0
                  ? props.tableHeaders.map((singleHeader, index) => {
                      return (
                        <StyledTableCell
                          key={index}
                          // className={classes.onlyDesktop}
                          className={
                            singleHeader.showInMobile
                              ? classes.onlyMobileTableCell +
                                classes.onlyDesktopTableCell
                              : classes.onlyDesktopTableCell
                          }
                          align={
                            singleHeader.align
                              ? singleHeader.align
                              : "left"
                          }
                        >
                          {translate(singleHeader.name)}
                        </StyledTableCell>
                      );
                    })
                  : ""}
              </TableRow>
            </TableHead>

            <TableBody>
            {props.storeData &&
              props.storeData.length > 0 ?
              props.storeData.map((singleStore, itemIndex) => {
                return (
                  <StyledTableRow key={"item_" + itemIndex}>
                      <StyledTableCell>
                        {singleStore.storeName}
                      </StyledTableCell>
                      <StyledTableCell className={classes.onlyDesktop}>
                        {singleStore.storeCode}
                      </StyledTableCell>
                      <StyledTableCell>
                        {singleStore.storeItemType}
                      </StyledTableCell>
                      <StyledTableCell className={classes.onlyDesktop}>
                        {singleStore.rebateStandard}
                      </StyledTableCell>
                      <StyledTableCell>
                        {singleStore.salesTarget}
                      </StyledTableCell>
                      <StyledTableCell>
                        {singleStore.investmentAmount}
                      </StyledTableCell>
                      <StyledTableCell className={classes.onlyDesktop}>
                        {singleStore.monthlyInvestmentAmount}
                      </StyledTableCell>
                      <StyledTableCell>
                        {singleStore.totalInvestmentAmount}
                      </StyledTableCell>
                  </StyledTableRow>
                )
            }) : (
              <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                colSpan={props.tableHeaders.length}
              >
                <NoRecord message={translate("No Records Found!")} />
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

PromotionDetailTableView.PropType = {};

export default withWidth()(PromotionDetailTableView);