import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LocalContext } from "../../LocalProvider.js";
import { getHeaderToken } from "../../store/selectors/navItems.selector";
import { totalPage, pagePerItems, getPaginatedData } from "../../utils/pagination";
import LoadingHOC from "../common/loader/LoadingHOC.component";
import { getPromotionsListHeader } from "../common/TableHeaders";
import { StyledTableCell, useStyles } from "./Promotion.styles";
import { selectedLanguage } from "../../store/selectors/language.selector";
import { getPromotionData, updateAttachmentCount } from "../../store/actions/promotion.action";

export const PromotionContext = createContext({
  setPromotionDetailData: () => {}
});

const PromotionContextProvider = (props) => {
  const token = useSelector(getHeaderToken);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { translate } = useContext(LocalContext);
  const classes = useStyles();
  const { setLoading } = props;
  const [promotionData, setPromotionData] = useState([]);
  const [promotionDataProvider, setPromotionDataProvider] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [promotionDetailData, setPromotionDetailData] = useState([]);
  const [attachmentStatus, setAttachmentStatus] = useState([]);
  const [dealerResponse, setDealerResponse] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const selectedLocLanguage = useSelector(selectedLanguage);
  const { promotionId } = useParams();
  const [totalPageCount, setTotalPageCount] = useState(0);

  // Update Attachment Status
  const updateAttachmentStatus = (token, fileId, dealerId) => {
    setLoading(true);
    let payload = {
      documentId: fileId,
      promotionId: promotionId,
      dealerId: dealerId,
      action: "readCount",
      dealerResponse: ""
    };

    dispatch(updateAttachmentCount(token, payload)).then((res) => {
      setLoading(false);
      if (res && res.success === false) {
        setErrorMsg(res.errorMsg);
        setSuccessMsg("");
        setAttachmentStatus([]);
      } else {
        setSuccessMsg(res.successMsg);
        if(res?.attachments?.length > 0) {
          setAttachmentStatus(res.attachments);
        }
        setErrorMsg("");
      }
    })
    .catch((err) => {
      setSuccessMsg("");
      setAttachmentStatus([]);
      setErrorMsg(err);
    });
  };

  // Update Dealer Response
  const updateDealerResponse = (token, fileId, dealerId, responseStatus) => {
    setLoading(true);
    let payload = {
      documentId: fileId,
      promotionId: promotionId,
      dealerId: dealerId,
      action: "dealerResponse",
      dealerResponse: responseStatus
    };

    dispatch(updateAttachmentCount(token, payload)).then((res) => {
      setLoading(false);
      if (res && res.success === false) {
        setErrorMsg(res.errorMsg);
        setSuccessMsg("");
        setDealerResponse("");
      } else {
        if(promotionDetailData?.length > 0) { // To Update Dealer Response In Listing API From Detail Page
          let oldPromotionDetailData = promotionDetailData[0];
          setPromotionDetailData([{  ...oldPromotionDetailData, dealerResponse: responseStatus }])
        }
        setDealerResponse(responseStatus);
        setSuccessMsg(res.successMsg);
        setErrorMsg("");
      }
    })
    .catch((err) => {
      setSuccessMsg("");
      setDealerResponse("");
      setErrorMsg(err);
    });
  };

  const handleChangePagination = (event, value) => {
    if (value !== page) {
      setPage(value);
    }
  };

  const handleSetPromotionDetails = (promotionDetails) => {
    setPromotionDetailData(promotionDetails);
  }

  const handelSetAttachmentAction = (fileId, dealerId) => {
    updateAttachmentStatus(token, fileId, dealerId);
  }

  const handleDealerResponse = (fileId, dealerId, responseStatus) => {
    updateDealerResponse(token, fileId, dealerId, responseStatus);
  }

  useEffect(() => {
    if(token) {
      setLoading(true);
      let payload = {
        offset: (page - 1) * pagePerItems,
        limit: pagePerItems,
        mu: "",
        ru: "",
        au: "",
        promotion_id: promotionId ? promotionId : ""
      };

      dispatch(getPromotionData(token, payload)).then((res) => {
        setLoading(false);
        if (res && res.success === false) {
          setErrorMsg(res.errorMsg);
          setPromotionData([]);
          setPromotionDataProvider([]);
          setTotalPageCount(0);
        } else {
          setPromotionData(res.data);
          const paginatedData = getPaginatedData(res.data, pagePerItems, 1);
          setPromotionDataProvider(paginatedData);
          setPromotionDetailData([]);
          setTotalPageCount(totalPage(res?.totalCount, pagePerItems));
          if(promotionId && paginatedData.length > 0) {
            const singlePromotionData = paginatedData.filter((singleData) => {
              return singleData.promotion.promotionId == promotionId;
            })
            setPromotionDetailData(singlePromotionData);            
          }
          setErrorMsg("");
        }
      });
    }
  }, [page]);

  return promotionDataProvider ? (
    <>
      <PromotionContext.Provider
        value={{
          promotionDataProvider,
          handleSetPromotionDetails,
          promotionDetailData,
          handelSetAttachmentAction,
          handleDealerResponse,
          dealerResponse,
          attachmentStatus,
          errorMsg,
          successMsg,
          getPromotionsListHeader,
          setLoading,
          setErrorMsg
        }}
      >
        {!promotionId ? (
          <>
            <div className={classes.headerTitleStyle}>
              <h2 className={classes.titleSummary}>
                {translate("My Project")}
              </h2>{" "}
            </div>              
          
            <TableContainer
              component={Paper}
              className={classes.tableContainerStyle}
              key={1}
            >
              <Table className={classes.table} aria-label="caption table">
                {getPromotionsListHeader && getPromotionsListHeader.length > 0 &&
                  <TableHead>
                    <TableRow>
                      {getPromotionsListHeader.map((singleHeader, index) => {
                        return <StyledTableCell>
                          <div key={index} align={singleHeader.align ? singleHeader.align : "left"}>
                            {translate(singleHeader.name)}
                          </div>
                        </StyledTableCell>
                      })
                      }
                    </TableRow>
                  </TableHead>
                }
                {props.children}
              </Table>
            </TableContainer>

            <div className={classes.buttonStyle}>
              <Pagination
                className={classes.pagination}
                size="large"
                count={totalPageCount}
                page={page}
                onChange={(e, value) => handleChangePagination(e, value)}
                color="primary"
                showFirstButton
                showLastButton
                boundaryCount={2}
              />
            </div>
          </>
        ) : props.children}
      </PromotionContext.Provider>
    </>
  ) : null;
};

export default LoadingHOC(PromotionContextProvider);