import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/styles";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LocalContext } from "../../LocalProvider.js";
import { moveCartItemsToCartAction } from "../../store/actions/cart.actions.js";
import { getCartItemsDataAction } from "../../store/actions/cartItems.actions.js";
import {
  fetchGetAccessToken,
  fetchGetCheckSupervisor,
  getCustomerArchiveStatus,
  updateLoginDetails,
} from "../../store/actions/navItems.actions";
import { getCartOrderTypeSelector } from "../../store/selectors/cartItems.selector.js";
import { getConfigData } from "../../store/selectors/navItems.selector.js";
import { colors, signInPics } from "../../styles/variables";
import { getEnv } from "../../utils/urls";
import ForgetPassword from "./../ForgetPassword.component";
import { SignInButton, useStyles } from "./Login.styles";

const Login = (props) => {
  const { translate } = useContext(LocalContext);
  const [open, setOpen] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
  const orderTypeCart = useSelector(getCartOrderTypeSelector);
  const configData = useSelector(getConfigData);

  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();
  const [values, setValues] = useState({
    //NZ
    email: "",
    password: "",
    showPassword: false,
  });

  const GreenRadio = withStyles({
    root: {
      color: "#0076B3",
      "&$checked": {
        color: "#00537D",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  /* TODO: Move API Calls to authentication */
  const signInClose = (event) => {
    event.preventDefault();
    if (parseInt(localStorage.getItem("invalidAttempt")) > 4) {
      setErrorMsg("Your account is locked please try again after 5 min");
    } else {
      localStorage.setItem("userId", values.email ? values.email : "");
      const payload = {
        customerId: values.email,
        password: values.password,
        openId: getEnv().apOpenId,
        displayName: getEnv().displayName,
      };
      dispatch(fetchGetAccessToken(payload))
        .then((res) => {
          if (res && res.success === false) {
            let invalidAttempt = localStorage.getItem("invalidAttempt")
              ? parseInt(localStorage.getItem("invalidAttempt")) + 1
              : 1;
            localStorage.setItem("invalidAttempt", invalidAttempt);
            setErrorMsg(res.msg);
          } else if (res.changePasswordRequired === true) {
            history.push({
              pathname: "/change-password",
              search: "?changepwd=1",
              state: {
                userType: res.userType,
                newUser: true,
              },
            });
          } else {
            dispatch(fetchGetCheckSupervisor(res.token, true)).then(
              (checkSuper) => {
                if (
                  checkSuper &&
                  checkSuper.associatedCustomers &&
                  checkSuper.associatedCustomers.length === 1
                ) {
                  if (
                    checkSuper &&
                    checkSuper.associatedCustomers &&
                    checkSuper.associatedCustomers[0].blocked === true
                  ) {
                    setErrorMsg(
                      "Contact Customer Service regarding login credentials"
                    );
                  }
                } else {
                  // setOpen(false);
                  setErrorMsg("");
                  dispatch(
                    updateLoginDetails(
                      res.token,
                      res.userType,
                      res.changePasswordRequired,
                      res.mtUser
                    )
                  );
                  // history.push("/home");
                }
                dispatch(getCartItemsDataAction(res.token)).then(() => {
                  dispatch(moveCartItemsToCartAction(orderTypeCart));
                });
              }
            );
            // setOpen(false);
            // setErrorMsg("");
            // if (res.userType !== "Profile") {
            //   history.push("/home");
            // }
            dispatch(getCustomerArchiveStatus(res.token));
          }
        })
        .catch((err) => {
          if (err && err.response) {
            setErrorMsg(err);
          } else {
            setErrorMsg("Your account is locked please try again after 5 min");
          }
        });
    }
    // }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (values.email.length > 2) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  };

  useEffect(() => {
    if (values.email.length > 2 && values.password.length > 2) {
      setIsDisable(false);
    }
  }, [values]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onForgetPassword = () => {
    setDisplayForgotPassword(true);
  };
  return (
    <div>
      {displayForgotPassword ? (
        <ForgetPassword handleCloseSignIn={props.handleCloseSignIn} />
      ) : (
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.paper }}
          fullWidth={true}
          maxWidth={false}
        >
          <Avatar className={classes.closeButton}>
            <IconButton aria-label="Close" onClick={props.handleCloseSignIn}>
              <CloseIcon />
            </IconButton>
          </Avatar>
          <DialogContent className={classes.dialogStyle}>
            <form className={classes.root} noValidate>
              <Grid container>
                <Grid item md={6} xs={12} className={classes.mobileCenter}>
                  <h1 className={classes.signInTitle}>
                    {translate("Sign In")}
                    <br />
                  </h1>
                  <Grid item xs={12}>
                    {errorMsg && (
                      <strong className={classes.passwordNotification}>
                        {translate(errorMsg)}
                      </strong>
                    )}
                    <FormControl
                      variant="outlined"
                      className={classes.gridMargin}
                    >
                      <InputLabel
                        htmlFor="component-outlined"
                        style={{ fontWeight: "bold" }}
                      >
                        {translate("User Name")}
                      </InputLabel>
                      <OutlinedInput
                        id="component-outlined"
                        value={values.email}
                        label={translate("User Name")}
                        onChange={handleChange("email")}
                        className={classes.inputBox}
                        type="email"
                        autoFocus
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.gridMargin}
                    >
                      <InputLabel
                        htmlFor="component-outlined"
                        style={{ fontWeight: "bold" }}
                      >
                        {translate("Password")}
                      </InputLabel>
                      <OutlinedInput
                        id="component-outlined-password"
                        value={values.password}
                        label={translate("Password")}
                        type={values.showPassword ? "text" : "password"}
                        onChange={handleChange("password")}
                        // onClick={handleClickNotification}
                        className={classes.inputBox}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <SignInButton
                      onClick={(e) => signInClose(e)}
                      className={`${classes.gridMargin} ${classes.BtnBlueBG}`}
                      disabled={isDisable}
                    >
                      {translate("Sign In")}
                    </SignInButton>
                    {/* {getEnv().siteId !== "2" && ( */}
                    {(
                      <DialogContentText >
                        {getEnv().siteId === "1" ? (
                          configData && configData.CustomerEmailUpdateStatus === "1" ? (
                            <Button
                              onClick={onForgetPassword}
                              className={classes.gridMargin}
                              style={{ color: `${colors.lightBlue}` }}
                            >
                              {translate("forgot.password")}
                            </Button>
                          ) : ""
                        ) : (
                          <>
                          {translate("forgot.password")}?{" "}
                          <Button
                            onClick={onForgetPassword}
                            style={{ color: `${colors.lightBlue}` }}
                          >
                            <strong>Click here</strong>
                          </Button>
                          </>
                        )}
                      </DialogContentText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  className={classes.mobileCenter}
                  align="center"
                >
                  <img
                    src={`${signInPics.drinkImage}`}
                    className={classes.drinkImage}
                    alt="login"
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

Login.propTypes = {};

export default Login;
