import Layout from "../components/layouts/Default.layout";
import BackgroundContainer from "../components/styled/BackGroundContainer.styled";
import SingleColumn from "../components/styled/SingleColumn.styled";
import PromotionContextProvider from "../components/promotions/PromotionContext";
import PromotionList from "../components/promotions/PromotionList";

const PromotionPage = (props) => {
  return (
    <Layout>
      <BackgroundContainer>
        <SingleColumn>
          <PromotionContextProvider>
            <PromotionList />
          </PromotionContextProvider>
        </SingleColumn>
      </BackgroundContainer>
    </Layout>
  );
};

PromotionPage.propTypes = {};

export default PromotionPage;
