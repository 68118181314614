const initialState = {
  navItems: { isAuth: false, token: "", changePasswordRequired: false },
  homePage: null,
  cart: {
    items: [],
  },
  specialCart: {
    items: [],
  },
  cartItems: {
    items: [],
  },
  language: {
    languageData: [],
    selectedLanguage: "en",
  },
  product: {
    items: [],
  },
  brandSubBrand: {
    brand: null,
    subBrand: null,
  },
  orderHistory: {
    items: [],
  },
  creditAmount: {
    items: [],
  },
  myAccount: {},
  root: {
    loading: 0,
  },
};

export default initialState;
