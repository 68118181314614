import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styled from "styled-components";
import { colors } from "../../styles/variables";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "1rem 0",
  },
  onlyDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  onlyMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  pagination: {
    display: "flex",
    paddingTop: "20px",
    justifyContent: "center",
  },
  onlyDesktopTableCell: {
    display: "table-cell",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  onlyMobileTableCell: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "table-cell",
    },
  },
  productQuantityWrapper: {
    fontWeight: "bold",
    fontSize: "0.8rem",
    textAlign: "center",
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
    },
    "& > *": {
      display: "inline-block",
    },
    "& button": {
      width: "24px !important",
      height: "24px !important",
      padding: "0 !important",
      minWidth: "24px !important",
      color: "#ffffff !important",
      "& svg": {
        fill: "#272725",
        "&:hover": {
          fill: "#FFA500",
        },
      },
    },
  },
  productQuantity: {
    display: "inline-block",
    width: "65px",
    margin: 0,
    color: "#30375B",
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      margin: "0 0.2rem",
    },
    "& > div": {
      "&:before": {
        borderBottomStyle: "none !important",
      },
    },
    "& input": {
      padding: "3px",
      textDecoration: "none",
      border: "1px solid #EDEDED",
      textAlign: "center",
      fontSize: "0.8rem",
      borderRadius: "0.15rem",
      "&:hover": {
        textDecoration: "none",
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
  },
  tableContainer: {
    borderRadius: "0.8rem",
  },
  buttonStyle: {
    textAlign: "right",
    marginTop: "1.75rem",
    marginBottom: "2.5rem",
    "& > *": {
      [theme.breakpoints.down("sm")]: {
        display: "inline-block",
        margin: "0 0 1rem 0",
      },
    },
  },
  remove: {
    position: "relative",
    display: "inline-block",
    marginRight: "0.4375rem",
    borderRadius: "50% !important",
    boxShadow: "none",
    backgroundColor: "#0076B3",
    "&:hover": {
      backgroundColor: "#272725",
    },
    "&:focus": {
      outline: "0.125rem solid #7AACFE !important",
    },
    "&:after": {
      position: "absolute",
      width: "1.5rem",
      height: "1.5rem",
      content: '"-"',
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
    },
    "& span": {
      display: "none",
    },
  },
  add: {
    position: "relative",
    display: "inline-block",
    marginLeft: "0.3125rem",
    borderRadius: "50% !important",
    boxShadow: "none",
    backgroundColor: "#0076B3",
    "&:hover": {
      backgroundColor: "#272725",
    },
    "&:focus": {
      outline: "0.125rem solid #7AACFE !important",
    },
    "&:after": {
      position: "absolute",
      width: "1.5rem",
      height: "1.5rem",
      content: '"+"',
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
    },
    "& span": {
      display: "none",
    },
  },
  table: {
    minWidth: 700,
    marginBottom: "0.75rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      marginBottom: "0.5rem",
    },
  },
  tableContainerStyle: {
    display: "block",
    borderRadius: "1.25rem",
    boxShadow: "0px 4px 10px #00000029",
  },
  productTitle: {
    fontSize: "1rem",
  },
  columnQtyPrice: {
    width: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  shoppingProductColumn: {
    width: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  deleteProductColumn: {
    width: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  quantityTitle: {
    fontSize: "1rem",
  },
  costTitle: {
    fontSize: "1rem",
  },
  headerTitleStyle: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0",
  },
  titleSummary: {
    paddingLeft: "2rem",
    color: "#535353",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  clearCart: {
    height: "2.375rem",
    backgroundColor: "white",
    fontWeight: "bold",
  },
  textCenter: {
    textAlign: "center",
    border: "1px dotted grey",
    padding: "0.5rem",
  },
  errorMsg: {
    fontSize: "1rem",
    color: "red",
    margin: "0.25rem 0 1rem 0",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem",
    },
  },
  shoppingIcon: {
    "&[disabled]": {
      opacity: 0.5,
    },
    width: "1rem",
    "& img": {
      maxWidth: "100%",
      width: "30px",
    },
    height: "1.25rem",
    margin: "0 auto",
  },
  deleteIcon: {
    width: "1rem",
    "& img": {
      maxWidth: "100%",
      width: "30px",
    },
    cursor: "pointer",
    height: "1.25rem",
    margin: "0 auto",
  },
  mediaHolder: {
    width: 50,
    height: 50,
    marginRight: "1rem",
    display: "table-cell",
    backgroundImage: "url(./product/transparent/Product_BG.png)",
    backgroundSize: "50px",
    backgroundPosition: "top",
    borderRadius: "0.5rem",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.down("sm")]: {
      marginRight: "0.25rem",
      minWidth: 50,
      backgroundSize: "50px 50px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
    },
  },
  mediaTable: {
    backgroundSize: "contain",
    objectFit: "cover",
    height: "50px",
    width: "50px",
  },
  mediaTableProductName: {
    display: "table-cell",
    verticalAlign: "middle",
    paddingLeft: "2rem",
    fontSize: "0.8rem",
    overflowY: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.25rem",
      maxWidth: "100px",
      lineHeight: "0.75rem",
    },
  },
  mediaPaddingLeftNone: {
    paddingLeft: "0rem !important",
  },
  selectStyle: {
    border: "1px solid #EDEDED",
    textAlign: "center",
    fontSize: "0.8rem",
    borderRadius: "0.15rem",
    maxWidth: "65px",
    "& select": {
      padding: "0.3rem 0.5rem",
    },
  },
  empty: {
    textAlign: "center",
    margin: "1rem 0",
  },
}));

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#30375B",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "0.9rem",
    lineHeight: "0.9rem",
    padding: "1rem 0.0rem 0.5 0.0rem",
  },
  body: {
    fontSize: "0.8rem",
    border: "0",
    padding: "0.25rem 1.0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 0rem 0.5rem 0.5rem",
      "&:first-child": {
        paddingLeft: "0.5rem",
      },
    },
  },
}))(TableCell);

export const CheckoutButton = styled.button`
  border: 1px solid ${colors.lightBlue};
  background: ${colors.white};
  color: ${colors.lightBlue};
  padding: 0.5em 1em;
  text-decoration: none;
  font-size: 0.8em;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: ${colors.lightBlue};
    color: ${colors.white};
  }
`;

export const ClearCartButton = styled.button`
  color: ${colors.lightBlue};
  padding: 0.5em 1em;
  border: 1px solid ${colors.lightBlue};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: #0076b3;
    color: #fff;
  }
`;
