export const rootActionTypes = {
  LOADING_START: "LOADING_START",
  LOADING_END: "LOADING_END",
  USER_LOGGED_IN: "USER_LOGGED_IN",
  USER_LOGGED_OUT: "USER_LOGGED_OUT",
};

export const loadingStart = () => {
  return {
    type: rootActionTypes.LOADING_START,
  };
};

export const loadingEnd = () => {
  return {
    type: rootActionTypes.LOADING_END,
  };
};

export const loggedIn = () => {
  return {
    type: rootActionTypes.USER_LOGGED_IN,
  };
};

export const loggedOut = () => {
  return {
    type: rootActionTypes.USER_LOGGED_OUT,
  };
};
