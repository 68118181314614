import { createGlobalStyle } from 'styled-components'

const FontFaces = createGlobalStyle`

@font-face {
  font-family: "SignPainter-HouseScript";
  src: url("/fonts/SignPainter-HouseScript.eot");
  src: url("/fonts/SignPainter-HouseScript.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/SignPainter-HouseScript.svg#SignPainter-HouseScript")
      format("svg"),
    url("/fonts/SignPainter-HouseScript.ttf") format("truetype"),
    url("/fonts/SignPainter-HouseScript.woff") format("woff"),
    url("/fonts/SignPainter-HouseScript.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FrontageCondensed-Inline";
  src: url("/fonts/FrontageCondensed-Inline.eot");
  src: url("/fonts/FrontageCondensed-Inline.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FrontageCondensed-Inline.svg#FrontageCondensed-Inline")
      format("svg"),
    url("/fonts/FrontageCondensed-Inline.ttf") format("truetype"),
    url("/fonts/FrontageCondensed-Inline.woff") format("woff"),
    url("/fonts/FrontageCondensed-Inline.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

`

export default FontFaces
