export const getEnv = () => {
  let languageEnv;
  if (localStorage.getItem("language")) {
    languageEnv = localStorage.getItem("language");
  } else {
    localStorage.setItem("language", process.env.REACT_APP_LANGUAGE_LOCAL);
    languageEnv = process.env.REACT_APP_LANGUAGE_LOCAL;
  }
  if (process.env.REACT_APP_HOST_LOCAL === window.location.hostname) {
    return {
      host: process.env.REACT_APP_HOST_LOCAL,
      url: process.env.REACT_APP_BASE_URL_LOCAL,
      siteId: process.env.REACT_APP_SITE_ID_LOCAL,
      xApiKey: process.env.REACT_APP_XAPIKEY_LOCAL,
      currency: process.env.REACT_APP_CURRENCY_LOCAL,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_LOCAL,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_LOCAL,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_LOCAL,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_LOCAL,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_LOCAL,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_LOCAL,
    };
  } else if (process.env.REACT_APP_HOST_CN_DEV === window.location.hostname) {
    // CN env details
    return {
      host: process.env.REACT_APP_HOST_CN_DEV,
      url: process.env.REACT_APP_BASE_URL_CN_DEV,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_DEV,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CN,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
    };
  } else if (process.env.REACT_APP_HOST_CN_PROD === window.location.hostname) {
    // CN env details
    return {
      host: process.env.REACT_APP_HOST_CN_DEV,
      url: process.env.REACT_APP_BASE_URL_CN_PROD,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_PROD,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CN,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
    };
  } else if (process.env.REACT_APP_HOST_AU_DEV === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_AU_DEV,
      url: process.env.REACT_APP_BASE_URL_AU_DEV,
      siteId: process.env.REACT_APP_SITE_ID_AU,
      xApiKey: process.env.REACT_APP_XAPIKEY_AU_DEV,
      currency: process.env.REACT_APP_CURRENCY_AU,
      language: process.env.REACT_APP_LANGUAGE_AU,
      displayName: process.env.REACT_APP_DISPLAY_NAME_AU,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_AU,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_AU,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_AU,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_AU,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_AU,
    };
  } else if (process.env.REACT_APP_HOST_AU_PROD === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_AU_DEV,
      url: process.env.REACT_APP_BASE_URL_AU_PROD,
      siteId: process.env.REACT_APP_SITE_ID_AU,
      xApiKey: process.env.REACT_APP_XAPIKEY_AU_PROD,
      currency: process.env.REACT_APP_CURRENCY_AU,
      language: process.env.REACT_APP_LANGUAGE_AU,
      displayName: process.env.REACT_APP_DISPLAY_NAME_AU,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_AU,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_AU,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_AU,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_AU,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_AU,
    };
  } else if (process.env.REACT_APP_HOST_NZ_DEV === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_NZ_DEV,
      url: process.env.REACT_APP_BASE_URL_NZ_DEV,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_DEV,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
    };
  } else if (process.env.REACT_APP_HOST_NZ_PROD === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_NZ_DEV,
      url: process.env.REACT_APP_BASE_URL_NZ_PROD,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_PROD,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
    };
  } else {
    return {
      host: window.location.hostname,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_DEV,
      // url: `${window.location.protocol}//${window.location.hostname}`,
      url: process.env.REACT_APP_BASE_URL_CN_DEV,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: process.env.REACT_APP_LANGUAGE_CN,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
    };
  }
};

export const getTranslations = `${getEnv().url}/getTranslations`;
export const accessTokenAPIUrl = `${getEnv().url}/accessToken`;
export const changePasswordUrl = `${getEnv().url}/changePassword`;
export const checkSupervisorUrl = `${getEnv().url}/checkSupervisor`;
export const validateTokenAPIUrl = `${getEnv().url}/validateToken`;
export const forgotPasswordAPIUrl = `${getEnv().url}/forgotPassword`;
export const homeAPIUrl = `${getEnv().url}/home`;
export const getNavigationsAPIUrl = `${getEnv().url}/getNavigations`;
export const productListAPIUrl = `${getEnv().url}/productList`;
export const specialProductListAPIUrl = `${getEnv().url}/specialProductList`;
export const bncProductListAPIUrl = `${getEnv().url}/bncProductList`;

export const productBrandsAPIUrl = `${getEnv().url}/productBrands`;
export const productSubBrandsAPIUrl = `${getEnv().url}/productSubBrands`;
export const productAddToWishListAPIUrl = `${getEnv().url}/addwishlistitem`;
export const productRemoveToWishListAPIUrl = `${
  getEnv().url
}/removewishlistitem`;

export const getOrderHistoryAPIUrl = `${getEnv().url}/orderHistory`;
export const getOrderDetailsAPIUrl = `${getEnv().url}/orderDetails`;
export const getCustomerDetailsAPIUrl = `${getEnv().url}/customerDetails`;
export const getMyAccountUrl = `${getEnv().url}/myAccount`;

export const getHelpInfoUrl = `${getEnv().url}/getHelpInfo`;
export const getContactUsInfoUrl = `${getEnv().url}/getContactUsInfo`;

export const getTermsDetailsAPIUrl = `${getEnv().url}/getCheckoutCondition`;
export const createOrderAPIUrl = `${getEnv().url}/createOrder`;
// export const addCartAPIUrl = `${url}/addCart`;
export const getCartAPIUrl = `${getEnv().url}/getCart`;
export const updateCartAPIUrl = `${getEnv().url}/updateCart`;
export const clearCartAPIUrl = `${getEnv().url}/clearCart`;
export const cancelOrderAPIUrl = `${getEnv().url}/cancelOrder`;
export const deleteOrderAPIUrl = `${getEnv().url}/deleteOrder`;
export const orderInfoAPIUrl = `${getEnv().url}/getOrderSKUDetails`;
export const creditAmountAPIUrl = `${getEnv().url}/creditAmount`;
export const statementOfAccountAPIUrl = `${getEnv().url}/statementOfAccounts`;
export const returnMaterialReasonCodeAPIUrl = `${
  getEnv().url
}/returnReasonCodes`;
export const createReturnOrderAPIUrl = `${getEnv().url}/createReturnOrder`;
export const downloadPDFReturnOrderAPIUrl = `${
  getEnv().url
}/downloadInvoicePDF`;
export const addReturnOrderImagesAPIUrl = `${
  getEnv().url
}/addReturnOrderImages`;
export const getWishListAPIUrl = `${getEnv().url}/getwishlist`;
export const clearWishListAPIUrl = `${getEnv().url}/clearwishlist`;
export const orderReportAPIUrl = `${getEnv().url}/reports/orderReport`;
export const getSuggestedOrderAPIUrl = `${getEnv().url}/suggestedOrder`;
export const getSuggestedOrderDetailAPIUrl = `${
  getEnv().url
}/suggestedOrderDetail`;
export const getCustomerArchiveStatusAPIUrl = `${getEnv().url}/getCustomerArchiveStatus`;
export const promotionListByDealerAPIUrl = `${getEnv().url}/promotionListByDealer`;
export const storeListByPromotionAPIUrl = `${getEnv().url}/storeListByPromotion`;
export const updateReadCountPromotion = `${getEnv().url}/updateDealerReadCountAndResponse`;
export const updateSuggestedOrderStatusAPIUrl = `${getEnv().url}/deleteSuggestedOrderForCustomer`;
export const getConfigurationsAPIUrl = `${getEnv().url}/getConfigurations`;

// API Header with Site Id
export const apiHeaderWithSiteId = {
  "X-Api-Key": getEnv().xApiKey,
  "Content-Type": "application/json",
  // "Content-Language": process.env.REACT_APP_LANGUAGE,
  "Content-Language": getEnv().language,
  SiteId: getEnv().siteId,
};