export const colors = {
  primary: "#00537D",
  primaryHover: "#063F5E",
  main: "#5f4f3d",
  blue: "#009ADC",
  lightBlue: "#0076B3",
  disable: "#dddddd",
  secondary: "#ec610d",
  green: "#008000",
  yellow: "#ffcc00",
  yellowHover: "#e2b502",
  greyDark: "#4b4b4b",
  black: "#000",
  blackVariant: "#231f20",
  lightGrey: "#c7c7c7",
  grey: "#c4c4c4",
  red: "#D92736",
  darkRed: "#B9222E",
  orange: "#f87131",
  white: "#ffffff",
  boxShadow: "#00000029",
  border: "#dddddd",
  heading: "#535353",
  transparantBg: "rgba(0, 0, 0, .3)",
};

export const fontSizes = {
  font12: "0.75rem", //12px
  font14: "0.875rem", //14px
  font16: "1rem", //16px
  font17: "1.063rem", //17px
  font18: "1.125rem", //18px
  font20: "1.25rem", //20px
  font24: "1.5rem", //24px
  font26: "1.529rem", //26px
  font28: "1.75rem", //28px
  font30: "1.875rem", //30px
  font36: "2.25rem", //36px
  font42: "2.625rem", //42px
  font48: "3rem", //48px
  font60: "3.75rem", //60px
  font64: "4rem", //60px
  font80: "5rem", //80px
  font90: "5.625rem", //90px
};

export const fontFamilies = {
  signPainter: `"SignPainter-HouseScript", sans-serif`,
  frontage: `"FrontageCondensed-Inline", sans-serif`,
  poppins: `'Poppins', sans-serif`,
  lato: `'Lato', sans-serif`,
};

export const breakpoints = {
  mobileMaxWidth: "991px",
  // container: "1280px",
  container: "1920px",
  containerStandard: "1366px",
  belowiPad: "768px",
};

export const mediaQueries = {
  mobileAndAbove: `(min-width: ${breakpoints.mobileMaxWidth})`,
  containerAndAbove: `(min-width: ${breakpoints.container})`,
  belowiPad: `(max-width: ${breakpoints.belowiPad})`,
  belowContainerStandard: `(max-width: ${breakpoints.containerStandard})`,
  container: `(max-width: ${breakpoints.container})`,
  belowContainer: `(max-width: ${breakpoints.container})`,
};

export const signInPics = {
  drinkImage: `./images/signInPic.png`,
};
export const bgSetting = {
  dottedPatternDesktop: `./background/Page_BG.png`,
  dottedPatternMobile: `./background/BG_mobile.png`,
  footerImage: `./background/Footer_BG.png`,
  dotImage: `./background/background_dots.png`,
  leftImage: `./background/background_left.png`,
  rightImage: `./background/background_right.png`,
};

export const cartPics = {
  deleteIcon: `./icons/delete-icon.png`,
  uploadImageIcon: `./icons/uploadImg-icon.png`,
  shoppingIcon: `./icons/Cart-icon.png`,
};

export const contactIcons = {
  writeIcon: "./icons/Write.png",
  callIcon: "./icons/Call.png",
};
