import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StyledTableCell, StyledTableRow, useStyles } from "./Promotion.styles";
import { PromotionContext } from "./PromotionContext";

const PromotionDetails = ({ promotionDataProvider }) => {
  const classes = useStyles();
  let history = useHistory();
  const promotionData = promotionDataProvider?.promotion;
  const {
    handleSetPromotionDetails
  } = useContext(PromotionContext);

  const handleDetailClick = (promotionId) => {
    handleSetPromotionDetails(promotionData);
    history.push(`/project-details/${promotionId}`);
  };

  if (!promotionDataProvider) {
    return null;
  } else {
    return (
      <>
        <StyledTableRow key={promotionData.promotionId}>
          <StyledTableCell
            component="th"
            scope="row"
          >
            <div>
              <strong
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }}
                onClick={() => handleDetailClick(promotionData.promotionId)}
              >{promotionData.promotionCode}</strong>
            </div>
          </StyledTableCell>
          <StyledTableCell>
            {promotionData.projectName} <br />
          </StyledTableCell>         

          <StyledTableCell>
            {promotionData.startTime}
          </StyledTableCell>

          <StyledTableCell>
            {promotionData.endTime}
          </StyledTableCell>

          <StyledTableCell align="center">
            {promotionDataProvider?.dealerResponse}
          </StyledTableCell>

          <StyledTableCell align="center">
            <div className={classes.productQuantityWrapper}>
              {promotionData?.attachmentCount}
            </div>
          </StyledTableCell>          
        </StyledTableRow>
      </>
    );
  }
};

export default PromotionDetails;
