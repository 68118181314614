import moment from "moment";

// TO GET FORMATED PRICE TO 2 DECIMALS
export const getFormatedPrice = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

// TO GET FORMATED DATE
export const getFormatedDate = (date, formatType) => {
  return moment(date).format(formatType);
};

// TO GET CURRENT DATE
export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

// TO GET SELECTED ASSOCIATED CUSTOMER
export const getSelectedAssociatedCustomer = () => {
  return localStorage.getItem("selectedCustomerID");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Type
export const getSelectedAssociatedCustomerType = () => {
  return localStorage.getItem("selectedCustomerType");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Sap ID
export const getSelectedAssociatedCustomerSapId = () => {
  return localStorage.getItem("selectedCustomerSapId");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Credit Amount
export const getSelectedAssociatedCustomerCreditAmt = () => {
  return localStorage.getItem("selectedCustomerCreditAmt");
};

// TO GET DEFAULT ROUTES ACCESSIBLE TO ALL USER
export const getDefaultRoutes = () => {
  return [
    { key: "home", link: "Home" },
    { key: "my-account", link: "My Account" },
    { key: "change-password", link: "Change Password" },
    { key: "cart-preview", link: "Cart Preview" },
    { key: "cart-summary", link: "Cart Summary" },
    { key: "health", link: "Health" },
    { key: "order-summary", link: "Order Summary" },
    { key: "order-details", link: "Order Details" },
    { key: "bulk-upload", link: "Bulk Upload" },
    { key: "suggested-order-detail", link: "Suggested Order Details" },
    { key: "project-details", link: "Project Details" }
  ]
};
