import { navItemsActionTypes } from "../actions/navItems.actions";

export default function navItemsReducer(state = null, action) {
  switch (action.type) {
    case navItemsActionTypes.FETCH_NAV_ITEMS_DATA_SUCCESS:
      if (action.isAuth) {
        return { ...state, authenticatedNav: action.data };
      } else {
        return { ...state, anonymousNav: action.data };
      }

    case navItemsActionTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth,
        token: action.token,
        userType: action.userType,
        changePasswordRequired: action.changePasswordRequired,
        mtUser: action.mtUser
      };

    case navItemsActionTypes.CHANGE_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth,
        token: action.token,
      };

    case navItemsActionTypes.CUSTOMER_ARCHIVE_SUCCESS:
      return { 
        ...state, 
        archiveData: action.data 
      };

    case navItemsActionTypes.CONFIGURATION_SUCCESS:
      return { 
        ...state, 
        configData: action.data 
      };

    default:
      return state;
  }
}
