// TO GET ORDER HISTORY LIST TABLE HEADERS
export const getOrderListHeaders = [
  { name: "Order ID", align: "left", showInMobile: true },
  {
    name: "Order Type",
    align: "left",
    showInMobile: false,
  },
  {
    name: "Order Status",
    align: "center",
    showInMobile: false,
  },
  {
    name: "PO Number",
    align: "center",
    showInMobile: false,
  },
  { name: "Items", align: "right", showInMobile: false },
  {
    name: "Order Date",
    align: "left",
    showInMobile: false,
  },
  {
    name: "Total Price",
    align: "right",
    showInMobile: true,
  },
  {
    name: "Edit Order",
    align: "center",
    showInMobile: false,
  },
  {
    name: "Return order",
    align: "center",
    showInMobile: false,
  },
  {
    name: "Cancel Order",
    align: "center",
    showInMobile: false,
  },
];

export const getOrderListForCNHeaders = [
  { name: "Order ID", align: "left", showInMobile: true },
  {
    name: "Order Type",
    align: "left",
    showInMobile: false,
  },
  {
    name: "Order Status",
    align: "center",
    showInMobile: false,
  },
  { name: "Items", align: "center", showInMobile: false },
  {
    name: "Order Date",
    align: "left",
    showInMobile: false,
  },
  {
    name: "Total Price",
    align: "right",
    showInMobile: true,
  },
  {
    name: "Edit Order",
    align: "center",
    showInMobile: true,
  },
  {
    name: "Cancel Order",
    align: "center",
    showInMobile: true,
  },
];

export const getOrderListHeadersChina = [
  { name: "Order ID", align: "left", showInMobile: true },
  {
    name: "Order Type",
    align: "left",
    showInMobile: false,
  },
  {
    name: "Order Status",
    align: "center",
    showInMobile: false,
  },
  { name: "Items", align: "right", showInMobile: false },
  {
    name: "Order Date",
    align: "left",
    showInMobile: false,
  },
  {
    name: "Total Price",
    align: "right",
    showInMobile: true,
  },
  {
    name: "Edit Order",
    align: "center",
    showInMobile: true,
  },
  {
    name: "Cancel Order",
    align: "center",
    showInMobile: true,
  },
];

// TO GET ORDER HISTORY DETAIL TABLE HEADERS
export const getOrderDetailHeaders = [
  { name: "Product", align: "left", showInMobile: true },
  { name: "Product Code", align: "left", showInMobile: false },
  { name: "Status", align: "center", showInMobile: false },
  { name: "Requested Date", align: "left", showInMobile: false },
  { name: "Ordered Quantity", align: "right", showInMobile: false },
  { name: "Unit Type", align: "center", showInMobile: false },
  { name: "Fullfilled Quantity", align: "right", showInMobile: false },
  {
    name: "Unit Price",
    align: "right",
    showInMobile: true,
  },
  { name: "Fulfilled Price", align: "right", showInMobile: true },
];

// TO GET ORDER HISTORY DETAIL TABLE HEADERS
export const getCreditAmountHeaders = [
  { name: "Sold To No", align: "left" },
  { name: "Sold To Name", align: "left" },
  { name: "Description", align: "left" },
  { name: "Document No.", align: "left" },
  { name: "Date", align: "left" },
  { name: "Due Date", align: "left" },
  { name: "Arrears", align: "right" },
  { name: "Reference", align: "left" },
  { name: "Assignment", align: "left" },
  { name: "Amount", align: "right" },
  { name: "Text", align: "left" },
];

export const getOrderReportHeaders = [
  { name: "Order Code", align: "center" },
  { name: "Order Date", align: "center" },
  { name: "Source", align: "center" },
  { name: "Ship To", align: "center" },
  { name: "Ship To Name", align: "center" },
  { name: "Item Code", align: "center" },
  { name: "Item Name", align: "center" },
  { name: "Brand Code", align: "center" },
  { name: "Brand", align: "center" },
  { name: "Sub Brand Code", align: "center" },
  { name: "Sub Brand", align: "center" },
  { name: "Order Cases", align: "center" },
  { name: "Order Value", align: "center" },
  { name: "Fulfill Cases", align: "center" },
  { name: "Fulfill Values", align: "center" },
];

export const getCartListHeaders = [
  { name: "Product", align: "" },
  { name: "Quantity", align: "center" },
  { name: "Unit Type", align: "right" },
  { name: "Unit Price", align: "center" },
  { name: "", align: "right" },
];
// TO GET STATEMENT OF ACCOUNTS TABLE HEADERS
export const getStatementOfAccountHeader = [
  { name: "Month", align: "left" },
  { name: "", align: "left" },
];

// TO GET STORE PROMOTION TABLE HEADERS
export const getStoreByPromotionHeader = [
  { name: "Store Name", align: "left" },
  { name: "Store Code", align: "left" },
  { name: "Store Item Type", align: "left" },
  { name: "Rebate Standard", align: "left" },
  { name: "Sales Target", align: "left" },
  { name: "Investment Amount", align: "left" },
  { name: "Monthly Investment Amount", align: "left" },
  { name: "Total Investment Amount", align: "left" },
];

// TO GET PROMOTION TABLE HEADERS
export const getPromotionsListHeader = [
  { name: "Project Code", align: "left" },
  { name: "Project Name", align: "left" },
  { name: "Start Date", align: "left" },
  { name: "End Date", align: "left" },
  { name: "Dealer Response", align: "center" },
  { name: "Documents", align: "center" }  
];

// TO GET PRODUCT TABLE HEADERS
export const getProductsListHeader = [
  { name: "Product", align: "left", display: true, showInMobile: true },
  { name: "Code", align: "center", display: true },
  { name: "Bar Code", align: "center" },
  { name: "Unit Price", align: "center", display: true, showInMobile: true },
  { name: "Quantity", align: "center", display: true, showInMobile: true },
  { name: "Unit Type", align: "center" },
  { name: "Add to Wishlist", align: "center", display: true, showInMobile: true } // FAVOURITE ICON
];

// TO GET SPECIAL PRODUCT TABLE HEADERS
export const getSpecialProductsListHeader = [
  { name: "Product", align: "left", display: true, showInMobile: true },
  { name: "Code", align: "center", display: true },
  { name: "Bar Code", align: "center" },
  { name: "Unit Price", align: "center", display: true, showInMobile: true },
  { name: "Quantity", align: "center", display: true, showInMobile: true }
];
