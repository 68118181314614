import LoadingHOC from "../components/common/loader/LoadingHOC.component";
import Layout from "../components/layouts/Default.layout";
import BackgroundContainer from "../components/styled/BackGroundContainer.styled";
import SingleColumn from "../components/styled/SingleColumn.styled";
import PromotionContextProvider from "../components/promotions/PromotionContext";
import PromotionDetailList from "../components/promotions/PromotionDetailList";

const PromotionDetail = (props) => {
  return (
    <Layout>
      <BackgroundContainer>
        <SingleColumn>
          <PromotionContextProvider>
            <PromotionDetailList />            
          </PromotionContextProvider>
        </SingleColumn>
      </BackgroundContainer>
    </Layout>
  );
};

PromotionDetail.propTypes = {};

export default LoadingHOC(PromotionDetail);