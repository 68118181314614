import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { colors } from "../../styles/variables";

export const SignInButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? colors.white : colors.lightBlue};
  color: ${(props) => (props.disabled ? colors.grey : colors.white)};
  padding: 0.5em 4.8em;
  border: ${(props) => (props.disabled ? `1px solid ${colors.grey}` : "none")};
  font-size: 1.3em;
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  &:hover {
    background-color: ${colors.primaryHover};
    color: ${colors.lightGrey};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
    width: 100%;
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
      },
    },
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  signInTitle: {
    paddingLeft: "1.75 em",
    marginLeft: "-1.625 em",
    marginBottom: "1.25 em",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      width: "100%",
      textAlign: "left",
    },
  },
  forgotButton: {
    paddingLeft: "1.75em",
    marginTop: "0.625em",
    marginBottom: "-0.4375em",
  },
  inputBox: {
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gridMargin: {
    marginBottom: "0.625em",
    marginTop: "1.25em",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  clickHereStyle: {
    color: "##0076B3",
    opacity: 1,
  },
  dialogStyle: {
    overflow: "hidden",
  },
  BtnBlueBG: {
    borderRadius: "0",
    height: "100%",
    width: "100%",
    "&:active": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.active": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
  },
  paper: {
    maxWidth: "46.125em",
    height: "auto",
    overflow: "hidden",
    background: "#f7f7f7",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      margin: 0,
      height: "100%",
      maxHeight: "100%",
    },
  },
  mobileCenter: {
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0 auto",
    },
  },
  drinkImage: {
    height: "16.6875em",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    zIndex: 1000,
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#lightgray",
  },
  passwordNotification: {
    fontSize: "0.625rem",
    color: "red",
    margin: "0.25rem 0 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem",
    },
    "& p": {
      fontSize: "0.625rem",
      margin: "0",
    },
  },
}));
