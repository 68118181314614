import { cartItemsActionTypes } from "../actions/cartItems.actions";
import { cartCheckoutActionTypes } from "../actions/cartCheckout.actions";

export default function cartItemsReducer(state = null, action) {
  switch (action.type) {
    case cartItemsActionTypes.FETCH_CART_ITEMS_SUCCESS:
      return {
        ...state,
        // items: [...action.data]
        items: action.data.items,
        productsCount: action.productsCount ? action.productsCount : null,
        getCartAPI: action.data
      };

    case cartItemsActionTypes.GET_CART_ITEMS_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        productsCount: action.productsCount ? action.productsCount : null,
        getCartAPI: action.data
      }
    }

    case cartCheckoutActionTypes.FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.data,
      };

    default:
      return state;
  }
}
