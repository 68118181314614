import { languageActionTypes } from "../actions/language.action";

export default function languageReducer(state = null, action) {
  switch (action.type) {
    case languageActionTypes.FETCH_LANGUAGE_DATA_SUCCESS:
      return {
        languageData: action.data.languageData,
        selectedLanguage: action.data.selectedLanguage,
      };
    default:
      return state;
  }
}
