import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import Authentication from "./components/common/auth/Authentication";
import Loading from "./components/common/Loading.component";
import PromotionPage from "./pages/Promotion.page";
import PromotionDetailPage from "./pages/PromotionDetail.page";
import Wishlist from "./pages/Wishlist.page";
import SessionTimeout from "./SessionTimeout";
import { allHeaderData, getPromotionStatusFlag } from "./store/selectors/navItems.selector";
import theme from "./theme";

const Home = lazy(() => import("./pages/Home.page"));
const Health = lazy(() => import("./pages/Health.page"));

const BulkUpload = lazy(() => import("./pages/BulkUpload.page"));
const CartPreview = lazy(() => import("./pages/CartPreview.page"));
const CartSummary = lazy(() => import("./pages/CartSummary.page"));
const ChangePassword = lazy(() => import("./pages/ChangePassword.page"));
const Contact = lazy(() => import("./pages/Contact.page"));
const CreditAmount = lazy(() => import("./pages/CreditAmount.page"));
const Help = lazy(() => import("./pages/Help.page"));
const MyAccount = lazy(() => import("./pages/MyAccount.page"));
const NotFound = lazy(() => import("./pages/NotFound.page"));
const OrderHistoryPage = lazy(() => import("./pages/OrderHistory.page"));
const BNCOrderHistoryPage = lazy(() => import("./pages/OrderHistory.page"));
const OrderHistoryDetailPage = lazy(() =>
  import("./pages/OrderHistoryDetail.page")
);
const OrderReport = lazy(() => import("./pages/OrderReport.page"));
const OrderSummary = lazy(() => import("./pages/OrderSummary.page"));
const Products = lazy(() => import("./pages/Products.page"));
const ReturnMaterial = lazy(() => import("./pages/ReturnMaterial.page"));
const SpecialProducts = lazy(() => import("./pages/SpecialProducts.page"));
const BNCProducts = lazy(() => import("./pages/BNCProducts.page"));
const BNCMultiProducts = lazy(() => import("./pages/BNCMultiProducts.page"));
const BNCMultiSpecialProducts = lazy(() => import("./pages/SpecialProducts.page"));
const BNCMultiOrderHistoryPage = lazy(() => import("./pages/OrderHistory.page"));
const BNCSpecialProducts = lazy(() => import("./pages/SpecialProducts.page"));
const StatementOfAccount = lazy(() =>
  import("./pages/StatementOfAccount.page")
);
const SuggestedOrderDetail = lazy(() =>
  import("./pages/SuggestedOrderDetail.page")
);
const SuggestedOrderSummary = lazy(() =>
  import("./pages/SuggestedOrderSummary.page")
);

const ProtectedRoute = ({ children, ...restProps }) => {
  if(restProps.path.includes("/projectList") || restProps.path.includes("/project-details")) {
    return restProps?.statusFlag === 1 ? <Route {...restProps}>
    	<Authentication>{children}</Authentication>
  	</Route> : <Redirect exact from="/" to="/home" />
  } else {
  return <Route {...restProps}>
    	<Authentication>{children}</Authentication>
  	</Route>
  }
};

const RouteRedirect = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); // url - /home?langauge=en
  const path = searchParams.get("path"); // https://exmaple.com/?path=/products

  if (path) {
    return <Redirect to={path} />;
  } else {
    return <Redirect exact from="/" to="/home" />;
  }
};

function App() {
  const headerData = useSelector(allHeaderData);
  const promotionStatusFlag = useSelector(getPromotionStatusFlag);
  const isAuthenticated = headerData.isAuth;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <SessionTimeout isAuthenticated={isAuthenticated} />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/health">
              <Health />
            </Route>

            <Route path="/change-password" component={ChangePassword}></Route>

            <Route path="/404" component={NotFound} />

            <ProtectedRoute exact path={["/shop", "/products"]}>
              <Products />
            </ProtectedRoute>

            <ProtectedRoute exact path="/special-products">
              <SpecialProducts isSpecialProduct={true} />
            </ProtectedRoute>

            <ProtectedRoute exact path="/bnc-products">
              <BNCProducts />
            </ProtectedRoute>

            <ProtectedRoute exact path="/bncmulti-products">
              <BNCMultiProducts />
            </ProtectedRoute>

            <ProtectedRoute exact path="/special-bnc-products">
              <BNCSpecialProducts isSpecialBNCProduct={true} />
            </ProtectedRoute>

            <ProtectedRoute exact path="/special-bncmulti-products">
              <BNCMultiSpecialProducts isSpecialBNCMultiProduct={true} />
            </ProtectedRoute>

            <ProtectedRoute path="/return-material">
              <ReturnMaterial />
            </ProtectedRoute>

            <ProtectedRoute path="/cart-preview">
              <CartPreview />
            </ProtectedRoute>

            <ProtectedRoute path="/cart-summary">
              <CartSummary />
            </ProtectedRoute>

            <ProtectedRoute path="/bulk-upload">
              <BulkUpload />
            </ProtectedRoute>

            <ProtectedRoute exact path={["/order-history", "/order"]}>
              <OrderHistoryPage />
            </ProtectedRoute>

            <ProtectedRoute exact path={["/bnc-order-history"]}>
              <BNCOrderHistoryPage isBNCOrder="true" />
            </ProtectedRoute>

            <ProtectedRoute exact path={["/bncmulti-order-history"]}>
              <BNCMultiOrderHistoryPage isBNCMultiOrder="true" />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/order-details/:orderId"
              // component={OrderHistoryDetailPage}
            >
              <OrderHistoryDetailPage />
            </ProtectedRoute>

            <ProtectedRoute path="/order-summary">
              <OrderSummary />
            </ProtectedRoute>

            <ProtectedRoute exact path="/credit-amount">
              <CreditAmount />
            </ProtectedRoute>

            <ProtectedRoute exact path={["/buy_again", "/buy-again"]}>
              <Wishlist />
            </ProtectedRoute>

            <ProtectedRoute exact path={"/projectList"} statusFlag={promotionStatusFlag}>
              <PromotionPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/project-details/:promotionId" statusFlag={promotionStatusFlag}>
              <PromotionDetailPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/suggested-order-summary">
              <SuggestedOrderSummary />
            </ProtectedRoute>

            <ProtectedRoute exact path="/order-report">
              <OrderReport />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/suggested-order-detail/:id/:orderTitle"
            >
              <SuggestedOrderDetail />
            </ProtectedRoute>

            <ProtectedRoute exact path="/my-account">
              <MyAccount />
            </ProtectedRoute>

            <ProtectedRoute exact path="/contact-us">
              <Contact />
            </ProtectedRoute>

            <ProtectedRoute exact path="/help">
              <Help />
            </ProtectedRoute>

            <ProtectedRoute exact path="/statement-of-account">
              <StatementOfAccount />
            </ProtectedRoute>

            <Route path="/" component={RouteRedirect} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
