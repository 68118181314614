import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import PropTypes from "prop-types";
import styled from "styled-components";
import { bgSetting, colors, mediaQueries } from "../../../styles/variables";
import Anchor from "../../styled/Anchor.styled";
import SingleColumn from "../../styled/SingleColumn.styled";

const FooterWrapper = styled.footer`
  background: url(/${bgSetting.footerImage}) no-repeat;
  background-size: 100% 100%;
  color: ${colors.white};
  overflow: hidden;
`;

const FooterLogoRow = styled.div`
  border-bottom: ${(props) =>
    props.bordered ? `1px solid ${colors.lightGrey}` : "none"};
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  margin: 3rem 0 1rem 0;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  font-size: 1rem;
  @media ${mediaQueries.belowContainerStandard} {
    margin: 1rem 0;
  }
  @media ${mediaQueries.belowiPad} {
    padding: 0 1rem;
    margin: 1rem 0;
    display: block;
    text-align: center;
  }
  & > * {
    @media ${mediaQueries.belowiPad} {
      display: block;
      margin: 1rem 0;
    }
  }
`;

const FooterRowCopyright = styled.div`
  border-bottom: ${(props) =>
    props.bordered ? `1px solid ${colors.lightGrey}` : "none"};
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  font-size: 0.8rem;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 0.7rem;
    margin: 1rem 0;
  }
  @media ${mediaQueries.belowiPad} {
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : 0)};
    text-align: center;
  }
`;

const socialIconsMap = {
  FacebookIcon: FacebookIcon,
  InstagramIcon: InstagramIcon,
  TwitterIcon: TwitterIcon,
};

const Footer = ({ footerPanelData }) => {
  const footerAPIData = footerPanelData;
  if (footerAPIData) {
    return (
      <FooterWrapper>
        <SingleColumn>
          <FooterLogoRow justify="space-between" bordered>
            <Anchor href={footerAPIData.logo.logoLink}>
              <img
                src={footerAPIData.logo.logoImgUrl}
                alt={footerAPIData.logo.logoImgAlt}
                style={{ width: 115 }}
              />
            </Anchor>
          </FooterLogoRow>

          <FooterRowCopyright
            justify="center"
            margin="2rem 0"
            paddingMobile="0 1rem 3rem 1rem"
          >
            {footerAPIData.copyrightText}
          </FooterRowCopyright>
        </SingleColumn>
      </FooterWrapper>
    );
  } else {
    return null;
  }
};

Footer.propTypes = {
  footerPanelData: PropTypes.object.isRequired,
};

export default Footer;
