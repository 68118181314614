import { cartCheckoutActionTypes } from "../actions/cartCheckout.actions";

export default function checkOutReducer(state = null, action) {
  switch (action.type) {
    case cartCheckoutActionTypes.FETCH_CART_CHECKOUT_DATA_SUCCESS:
        return { 
            ...state,
            customerDetails: action.data
        };

    case cartCheckoutActionTypes.FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS:
        return { 
            ...state,
            termsDetails: action.data
        };
        
    default:
        return state;
  }
}