import { cartActionTypes } from "../actions/cart.actions";

export default function cartReducer(state = null, action) {
  switch (action.type) {
    case cartActionTypes.FETCH_CART_DATA_SUCCESS:
      return { items: [...action.data] };
    default:
      return state;
  }
}
