import { getSelectedAssociatedCustomer } from "../../components/common/helper/helper";
import {
  apiHeaderWithSiteId,
  createOrderAPIUrl,
  getCustomerDetailsAPIUrl,
  getTermsDetailsAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const cartCheckoutActionTypes = {
  FETCH_CART_CHECKOUT_DATA_SUCCESS: "FETCH_CART_CHECKOUT_DATA_SUCCESS",
  FETCH_CART_CHECKOUT_DATA_FAILTURE: "FETCH_CART_CHECKOUT_DATA_FAILURE",
  FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS:
    "FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS",
  FETCH_CART_CHECKOUT_TERMS_DATA_FAILURE:
    "FETCH_CART_CHECKOUT_TERMS_DATA_FAILURE",
  FETCH_ORDER_DETAILS_SUCCESS: "FETCH_ORDER_DETAILS_SUCCESS",
  FETCH_ORDER_DETAILS_FAILURE: "FETCH_ORDER_DETAILS_FAILURE",
};

const fetchCartCheckoutSuccessAction = (data) => {
  return {
    type: cartCheckoutActionTypes.FETCH_CART_CHECKOUT_DATA_SUCCESS,
    data,
  };
};

const fetchCartCheckoutTermsSuccessAction = (data) => {
  return {
    type: cartCheckoutActionTypes.FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS,
    data,
  };
};

const fetchCreateOrderSuccessAction = (data) => {
  return {
    type: cartCheckoutActionTypes.FETCH_ORDER_DETAILS_SUCCESS,
    data,
  };
};

// TO GET CURRENT LOGGED-IN USER BILL,SHIP & SOLD TO ADDRESS
export const fetchCustomerDetailsAction =
  (isAuth, token) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    await fetch(getCustomerDetailsAPIUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchCartCheckoutSuccessAction(data));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

// TO GET TERMS & CONDITIONS TO PLACE ORDER
export const fetchTermsDetailsAction = (isAuth, token) => async (dispatch) => {
  dispatch(loadingStart());
  let headers = apiHeaderWithSiteId;
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  await fetch(getTermsDetailsAPIUrl, {
    method: "GET",
    headers: headers,
  })
    .then((responseData) => responseData.json())
    .then((result) => {
      dispatch(fetchCartCheckoutTermsSuccessAction(result));
      dispatch(loadingEnd());
      return result;
    })
    .catch((err) => console.log(err));
};

// TO PLACE NEW ORDER
export const addOrderDataAction =
  (isAuth, token, payload) => (dispatch, getState) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }
    return fetch(createOrderAPIUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        let latestState = getState();
        latestState.cartItems.productsCount = 0;
        let orderDetails = {
          orderNumber: data.orderNumber,
          billTo: payload.billTo,
          shipTo: payload.shipTo,
          soldTo: payload.soldTo,
          orderDate: data.orderDate,
        };
        dispatch(fetchCreateOrderSuccessAction(orderDetails));
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => console.log(err));
  };
