import Layout from "../components/layouts/Default.layout";
import BackgroundContainer from "../components/styled/BackGroundContainer.styled";
import SingleColumn from "../components/styled/SingleColumn.styled";
import WishlistContextProvider from "../components/wishlist/WishlistContext";
import WishlistList from "../components/wishlist/WishlistList";

const Wishlist = (props) => {
  return (
    <Layout>
      <BackgroundContainer>
        <SingleColumn>
          <WishlistContextProvider>
            <WishlistList />
          </WishlistContextProvider>
        </SingleColumn>
      </BackgroundContainer>
    </Layout>
  );
};

Wishlist.propTypes = {};

export default Wishlist;
