import { getSelectedAssociatedCustomer } from "../../components/common/helper/helper";
import {
  apiHeaderWithSiteId,
  promotionListByDealerAPIUrl,
  updateReadCountPromotion,
  storeListByPromotionAPIUrl
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

// Get Promotion Data List
export const getPromotionData = (token, payload) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
        headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }    
    return await fetch(promotionListByDealerAPIUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(payload),
    })
    .then((response) => response.json())
    .then((data) => {
        dispatch(loadingEnd());
        return data;
    })
    .catch((err) => {
        return err;
    });
};

// Update Attachments Read Count Of Promotion & Dealer Response
export const updateAttachmentCount = (token, payload) => async (dispatch) => {
    dispatch(loadingStart());
    let headers = apiHeaderWithSiteId;
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
        headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }    
    return await fetch(updateReadCountPromotion, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(payload),
    })
    .then((response) => response.json())
    .then((data) => {
        dispatch(loadingEnd());
        return data;
    })
    .catch((err) => {
        return err;
    });
};

// Get All Store Info For Selected Promotion
export const getStoreListByPromotion = (token, payload) => async (dispatch) => {
    dispatch(loadingStart());
      let headers = apiHeaderWithSiteId;
      if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
        if (getSelectedAssociatedCustomer() !== null) {
        headers.AssociatedCustomer = getSelectedAssociatedCustomer();
      }
    return await fetch(storeListByPromotionAPIUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(payload),
      })
      .then((response) => response.json())
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        return err;
      });
};