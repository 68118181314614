import { Button, FormControl, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState, useContext } from "react";
import { LocalContext } from "../../LocalProvider";
import { getSelectedAssociatedCustomer } from "./helper/helper";

export default function ProfileDialog(props) {
  const classes = useStyles();
  const [radioDisabled, setRadioDisabled] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedCustomerId = getSelectedAssociatedCustomer();
  const [value, setValue] = useState(
    selectedCustomerId !== null && selectedCustomerId !== undefined
      ? selectedCustomerId
      : ""
  );
  const { translate } = useContext(LocalContext);

  const GreenRadio = withStyles({
    root: {
      color: "#0076B3",
      "&$checked": {
        color: "#00537D",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  // In case of multiple associated customers
  const handleChange = (event) => {
    const sapIdAndCreditAmt = event.target.id;
    const sapIdAndCreditAmtValues = sapIdAndCreditAmt.split(" ");
    setValue(parseInt(event.target.value));
    setRadioDisabled(false);
    localStorage.setItem("selectedCustomerID", event.target.value);
    localStorage.setItem("selectedCustomerType", event.target.name);
    localStorage.setItem("selectedCustomerSapId", sapIdAndCreditAmtValues[0]);
    localStorage.setItem(
      "selectedCustomerCreditAmt",
      Math.abs(sapIdAndCreditAmtValues[1])
    );
  };

  const blockedUserLabel = () => {
    return (
      <span style={{ color: "red" }}>
        {translate("Contact Customer Service regarding login credentials")}{" "}
      </span>
    );
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={
          selectedCustomerId !== null && selectedCustomerId !== undefined
            ? props.handlecloseprofile(null)
            : ""
        }
        aria-labelledby="responsive-dialog-title"
        className={classes.dialogStyle}
        style={{ width: "100%" }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.dialogTitle}
        >
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {translate("Please select customer to proceed.")}
            <FormControl component="fieldset" style={{ display: "block" }}>
              {props.dropdowndata &&
                props.dropdowndata.map((data, key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      className={classes.FormControlLabelStyle}
                      control={
                        <GreenRadio
                          {...props}
                          checked={parseInt(value) === data.id}
                          onChange={handleChange}
                          name={data.type}
                          value={data.id}
                          id={data.sapCode + " " + data.creditBalance}
                          disabled={data.blocked}
                          data-isprm={data.creditBalance}
                        />
                      }
                      label={data.sapCode + ":  " + data.name}
                    />
                  );
                })}
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handlecloseprofile(value)}
            color="primary"
            autoFocus
            disabled={radioDisabled}
          >
            {translate("Continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  dialogStyle: {
    width: "100%",
  },
  dialogTitle: {
    backgroundColor: "#0076B3",
    padding: "0.75rem 1rem",
    color: "#fff",
    textTransform: "uppercase",
  },
}));

ProfileDialog.propTypes = {};
