import { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageAction } from "./store/actions/language.action";
import {
  getLanguage,
  selectedLanguage,
} from "./store/selectors/language.selector";

export const LocalContext = createContext();
// export const WishlistContext = createContext();

const useTranslate = () => {
  const { translate } = useContext(LocalContext);
  return translate;
};

function LocalProvider({ children }) {
  const dispatch = useDispatch();
  const contentLan = useSelector(getLanguage);
  const selectedLocaleLang = useSelector(selectedLanguage);
  const getLanguageSelector = useSelector(getLanguage);

  useEffect(() => {
    // if (getEnv().language === "zh") {
    //   console.log("change lang from local provider", getEnv().language);
    //   localStorage.setItem("language", "zh");
    // } else {
    //   localStorage.setItem("language", "en");
    // }
    // api call when language not found
    if (contentLan.length === 0 && window.location.pathname !== "/health") {
      dispatch(getLanguageAction());
    }
  });

  const translate = (text) => {
    if (!contentLan[text]) {
      return text;
    }
    if (selectedLocaleLang) {
      return contentLan[text][selectedLocaleLang];
    } else {
      return text;
    }
  };

  return (
    <LocalContext.Provider value={{ translate, getLanguageSelector }}>
      {children}
    </LocalContext.Provider>
  );
}

LocalProvider.propTypes = {};

export default LocalProvider;
