import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalContext } from "../../LocalProvider.js";
import { getCartItemsDataAction } from "../../store/actions/cartItems.actions";
import { removeToWishListDataAction } from "../../store/actions/product.actions.js";
import {
  getHeaderToken,
  isAuthenticated
} from "../../store/selectors/navItems.selector";
import { totalPage, getPaginatedData, pagePerItems } from "../../utils/pagination";
import {
  apiHeaderWithSiteId,
  clearWishListAPIUrl,
  getEnv,
  getWishListAPIUrl,
  updateCartAPIUrl
} from "../../utils/urls";
import { getSelectedAssociatedCustomer } from "../common/helper/helper";
import LoadingHOC from "../common/loader/LoadingHOC.component";
import {
  CheckoutButton,
  ClearCartButton,
  StyledTableCell,
  useStyles
} from "./Wishlist.styles";
import { selectedLanguage } from "../../store/selectors/language.selector";

export const WishlistContext = createContext();

const WishlistContextProvider = (props) => {
  const isAuth = useSelector(isAuthenticated);
  const token = useSelector(getHeaderToken);
  const selectedLocLanguage = useSelector(selectedLanguage);
  const [page, setPage] = useState(1);
  const { translate } = useContext(LocalContext);
  const classes = useStyles();
  const { setLoading } = props;
  const dispatch = useDispatch();
  const [wishlistData, setWishlistData] = useState([]);
  const [wishlistDataProvider, setWishlistDataProvider] = useState([]);
  const [clearWishList, setClearWishList] = useState(false);
  const [wishlistArrayPayload, setWishlistArrayPayload] = useState({
    orderType: "ZOR",
    items: [],
  });
  const [errorMsg, setErrorMsg] = useState("");
  const totalPageCount = totalPage(wishlistData.length, pagePerItems);

  // Get WISH LIST DATA Handler
  const getWishListData = (token) => {
    setLoading(true);
    let associateCustmerID = localStorage.getItem("selectedCustomerID");
    let headers = apiHeaderWithSiteId;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    let payload = {
      offset: 0,
      limit: pagePerItems,
    };

    if (associateCustmerID !== null) {
      headers.AssociatedCustomer = associateCustmerID;
    }
    fetch(getWishListAPIUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res && res.success === false) {
          setErrorMsg(res.msg);
          setWishlistData([]);
          setWishlistDataProvider([]);
          setClearWishList(true);
        } else {
          //Get the data from Get wishlist API
          dispatch(getCartItemsDataAction(token));
          setWishlistData(res.items);
          const paginatedData = getPaginatedData(res.items, pagePerItems, 1);
          setWishlistDataProvider(paginatedData);
          setErrorMsg("");
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };

  const handleChangePagination = (event, value) => {
    if (value !== page) {
      setPage(value);
      const paginatedData = getPaginatedData(wishlistData, pagePerItems, value);
      setWishlistDataProvider(paginatedData);
    }
  };

  //Wishlist Page Quantity Update Handler
  const updateWishlistItemsInputData = (
    productInfo,
    value,
    isAuth,
    token,
    payload
  ) => {
    if (productInfo.count >= 1) {
      let headers = apiHeaderWithSiteId;
      if (isAuth && token) {
        headers.Authorization = `Bearer ${token}`;
      }
      if (getSelectedAssociatedCustomer() !== null) {
        headers.AssociatedCustomer = getSelectedAssociatedCustomer();
      }
      fetch(updateCartAPIUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
    }
  };

  const addToCartWishlist = () => {
    moveCartToCartItemsDataAction(isAuth, token, wishlistDataProvider);
  };

  //Product Listing Page from Wishlist Page on Add to Cart Button
  const moveCartToCartItemsDataAction = (isAuth, token, rmaDataCollection) => {
    setLoading(true);
    // get product id and update count
    let updateCartPayload = {
      orderType: "ZOR",
      items: rmaDataCollection.map((cartData) => ({
        id: cartData.id,
        quantity:
          cartData.count === 0 || cartData.count === ""
            ? 0
            : parseInt(cartData.count),
        unitType: cartData.unitType
          .filter((type) => type.active === 1)
          .map((singleUnitType) => {
            return singleUnitType.key;
          })[0],
      })),
    };

    let headers = apiHeaderWithSiteId;
    if (isAuth && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (getSelectedAssociatedCustomer() !== null) {
      headers.AssociatedCustomer = getSelectedAssociatedCustomer();
    }

    fetch(updateCartAPIUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(updateCartPayload),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success === false) {
          setErrorMsg(data.msg);
        } else {
          setErrorMsg("");
          let productsWithCount = {};
          let defaultCount = 0;
          data.items &&
            data.items.length > 0 &&
            data.items.forEach((cartData) => {
              productsWithCount[cartData.id] = cartData.count;
              defaultCount = parseInt(cartData.count) + parseInt(defaultCount);
            });
          productsWithCount["defaultCount"] = parseInt(defaultCount);
          dispatch(getCartItemsDataAction(token));
        }
        return data;
      })
  };

  // Clear WishlistAction Handlder
  const clearWishListData = (token) => {
    setLoading(true);
    let associateCustmerID = localStorage.getItem("selectedCustomerID");
    let headers = apiHeaderWithSiteId;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (associateCustmerID !== null) {
      headers.AssociatedCustomer = associateCustmerID;
    }
    fetch(clearWishListAPIUrl, {
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        return data;
      })
  };

  //call orderHistory api to use as cartItemData
  useEffect(() => {
    getWishListData(token);
  }, [token, selectedLocLanguage]);

  // handle wishlistData and setting WishlistArrayPayload
  useEffect(() => {
    let payloadDataAyy = [];
    wishlistData &&
      wishlistData.length > 0 &&
      wishlistData.map((singleSubOrder) => {
        let payloadData = {
          id: singleSubOrder.id,
          quantity: singleSubOrder.count,
          unitType: singleSubOrder.unitType
            .filter((type) => type.active === 1)
            .map((singleUnitType) => {
              return singleUnitType.key;
            })[0],
        };
        payloadDataAyy.push(payloadData);
        return payloadData;
      });
    setWishlistArrayPayload({
      orderType: "ZOR",
      items: payloadDataAyy,
    });
    //
  }, [wishlistData]);

  const handelRemoveItem = (productInfo) => {
    dispatch(removeToWishListDataAction(productInfo, token));
    let filteredDataProvider = wishlistDataProvider.filter(
      (data) => data.id !== productInfo.id
    );
    setWishlistDataProvider(filteredDataProvider);
    let filteredWishlistData = wishlistData.filter(
      (data) => data.id !== productInfo.id
    );
    setWishlistData(filteredWishlistData);
    for (var i = 0; i < wishlistArrayPayload.items.length; i++) {
      if (wishlistArrayPayload.items[i].id === productInfo.id) {
        wishlistArrayPayload.items.splice(i, 1);
      }
    }
    setWishlistArrayPayload(wishlistArrayPayload);
    // updateWishlistItemsInputData(
    //   productInfo,
    //   productInfo.count,
    //   isAuth,
    //   token,
    //   wishlistArrayPayload
    // );
  };

  const handelQtyPlaceholder = (e, productInfo) => {
    if (e === "0") {
      productInfo.count = "";
      let wishData =
        wishlistDataProvider.length > 0 &&
        wishlistDataProvider.map((data, index) => {
          if (data.id === productInfo.id) {
            let ftrDataUpdated = wishlistDataProvider.filter(
              (dataUpdated) => dataUpdated.id === productInfo.id
            );
            ftrDataUpdated.count = productInfo.count;
          }
          return data;
        });
      setWishlistDataProvider(wishData);

      wishlistArrayPayload.items.length > 0 &&
        wishlistArrayPayload.items.map((data, index) => {
          if (data.id === productInfo.id) {
            return (wishlistArrayPayload.items[index].quantity =
              productInfo.count);
          }
          // added to fix return message
          return wishlistArrayPayload;
        });

      setWishlistArrayPayload(wishlistArrayPayload);
    }
    // updateWishlistItemsInputData(productInfo.count);
  };

  // Product Quantity update event
  const handelRMAQty = (e, productQuantity, productInfo) => {
    // Disabaled the dispatch event in case of blank string and value should be greater than zero
    //max length should be three digit

    if (isNaN(productQuantity)) {
      e.preventDefault();
    }

    if (productQuantity < 9 || productQuantity > 0) {
      // Incase of minimum quantity, we are restricting it to 0
      if (productQuantity === "") {
        productInfo.count = 0;
        // updateWishlistItemsInputData(productInfo.count);
      }
      //max length should be three digit
      if (productQuantity.length <= 3 && productQuantity != 0) {
        productQuantity = parseInt(productQuantity);
        productInfo.count = productQuantity;
        // updateWishlistItemsInputData(productInfo.count);
      }
    }

    let wishData =
      wishlistDataProvider.length > 0 &&
      wishlistDataProvider.map((data, index) => {
        if (data.id === productInfo.id) {
          let ftrDataUpdated = wishlistDataProvider.filter(
            (dataUpdated) => dataUpdated.id === productInfo.id
          );
          ftrDataUpdated.count = productInfo.count;
        }
        return data;
      });

    setWishlistDataProvider(wishData);

    wishlistArrayPayload.items.length > 0 &&
      wishlistArrayPayload.items.map((data, index) => {
        if (data.id === productInfo.id) {
          return (wishlistArrayPayload.items[index].quantity =
            productInfo.count);
        }
        // added to fix return message
        return wishlistArrayPayload;
      });

    setWishlistArrayPayload(wishlistArrayPayload);
    // updateWishlistItemsInputData(
    //   productInfo,
    //   productInfo.count,
    //   isAuth,
    //   token,
    //   wishlistArrayPayload
    // );
  };

  const handleUnitType = (event, productId, productInfo) => {
    let updatedRmaData = wishlistDataProvider.map((data) => {
      if (data.id === productId) {
        data.unitType.map((unitKey) => {
          if (unitKey.key === event.target.value) {
            unitKey.active = 1;
          } else {
            unitKey.active = 0;
          }
          // added to fix return message
          return data;
        });
      }
      return data;
    });

    setWishlistDataProvider(updatedRmaData);
    wishlistArrayPayload.items.map((data, index) => {
      if (data.id === productId) {
        return (wishlistArrayPayload.items[index].unitType =
          event.target.value);
      }
      // added to fix return message
      return data;
    });
    setWishlistArrayPayload(wishlistArrayPayload);
    updateWishlistItemsInputData(
      productInfo,
      productInfo.count,
      isAuth,
      token,
      wishlistArrayPayload
    );
  };

  // Clear Wishlist Handler
  const clearWishlistHandler = () => {
    clearWishListData(token);
    setWishlistData([]);
    setWishlistDataProvider([]);
    setClearWishList(true);
  };

  if (wishlistDataProvider) {
    return (
      <>
        <div className={classes.headerTitleStyle}>
          <h2 className={classes.titleSummary}>{translate("Your Wishlist")}</h2>{" "}
          <ClearCartButton
            className={classes.clearCart}
            onClick={() => clearWishlistHandler()}
          >
            {translate("Clear Wishlist")}
          </ClearCartButton>
        </div>
        {errorMsg && (
          <div className={classes.errorMsg}>{translate(errorMsg)}</div>
        )}
        <WishlistContext.Provider
          value={{
            wishlistDataProvider,
            clearWishList,
            handelRemoveItem,
            handelQtyPlaceholder,
            handelRMAQty,
            handleUnitType,
          }}
        >
          {clearWishList.length === 0 ? (
            !clearWishList ? (
              <p className={classes.textCenter}>
                {translate("Your wishlist is Loading")} ...
              </p>
            ) : (
              <p className={classes.textCenter}>
                {translate("Your wishlist is Empty")}
              </p>
            )
          ) : (
            <>
              <TableContainer
                component={Paper}
                className={classes.tableContainerStyle}
                key={1}
              >
                <Table className={classes.table} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <div className={classes.productTitle}>
                          {translate("Product")}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        className={classes.onlyDesktopTableCell}
                      >
                        <div className={classes.productTitle}>
                          {translate("Code")}
                        </div>
                      </StyledTableCell>
                      {getEnv().displayBarCode === "true" && (
                        <StyledTableCell
                          align="center"
                          className={classes.onlyDesktopTableCell}
                        >
                          <div className={classes.productTitle}>
                            {translate("Bar Code")}
                          </div>
                        </StyledTableCell>
                      )}
                      <StyledTableCell
                        align="center"
                        className={classes.columnQtyPrice}
                      >
                        <div className={classes.quantityTitle}>
                          {translate("Quantity")}
                        </div>
                      </StyledTableCell>
                      {getEnv().displayUnitType === "true" && (
                        <StyledTableCell
                          align="center"
                          className={
                            classes.columnQtyPrice +
                            " " +
                            classes.onlyDesktopTableCell
                          }
                        >
                          <div
                            className={
                              classes.costTitle +
                              " " +
                              classes.onlyDesktopTableCell
                            }
                          >
                            {translate("Unit Type")}
                          </div>
                        </StyledTableCell>
                      )}
                      <StyledTableCell
                        align="center"
                        className={classes.columnQtyPrice}
                      >
                        <div className={classes.costTitle}>
                          {translate("Unit Price")}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className={classes.shoppingProductColumn}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className={classes.deleteProductColumn}
                      ></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {props.children}
                </Table>
              </TableContainer>

              <div className={classes.buttonStyle}>
                {wishlistData.length > 0 && (
                  <Pagination
                    className={classes.pagination}
                    size="large"
                    count={totalPageCount}
                    page={page}
                    onChange={handleChangePagination}
                    color="primary"
                    showFirstButton
                    showLastButton
                    boundaryCount={2}
                  />
                )}
                <CheckoutButton onClick={() => addToCartWishlist()}>
                  <strong>{translate("Add to cart").toUpperCase()}</strong>
                </CheckoutButton>
              </div>
            </>
          )}
        </WishlistContext.Provider>
      </>
    );
  } else {
    return null;
  }
};

export default LoadingHOC(WishlistContextProvider);
